// import React from 'react';
import React, { useEffect, useState } from 'react';
import "./Home.css"
import { NavLink, useNavigate } from "react-router-dom";
import Beforefooter from '../../Images/home/footer-top.svg';
import Popup from '../../Components/Popup/Popup';
import BannerImage from '../../Images/home/banner-circle.png'
import NagriLogoBlack from '../../Images/home/nagri-black.svg'
import AboutImg from '../../Images/home/why-nagri.svg'
import img01 from '../../Images/event/NGR_001.jpeg'
import img02 from '../../Images/event/NGR_002.jpeg'
import img03 from '../../Images/event/NGR_003.jpeg'
import img04 from '../../Images/event/NGR_004.jpeg'
import img05 from '../../Images/event/NGR_005.jpeg'
import img06 from '../../Images/event/NGR_006.jpeg'
import img07 from '../../Images/event/NGR_007.jpeg'
import img08 from '../../Images/event/NGR_008.jpeg'
import img09 from '../../Images/event/NGR_009.jpeg'
import img10 from '../../Images/event/NGR_0010.jpeg'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, EffectCoverflow, Pagination } from 'swiper/modules';
import { BsTicketPerforated } from 'react-icons/bs';
import FAQs from './FAQs';


const itemsa = [
    {
        id: 1,
        imageUrl: img01,
    },
    {
        id: 2,
        imageUrl: img02,
    },
    {
        id: 3,
        imageUrl: img03,
    },
    {
        id: 4,
        imageUrl: img04,
    },
    {
        id: 5,
        imageUrl: img05,
    },
    {
        id: 6,
        imageUrl: img06,
    },
    {
        id: 7,
        imageUrl: img07,
    },
    {
        id: 8,
        imageUrl: img08,
    },
    {
        id: 9,
        imageUrl: img09,
    },
    {
        id: 10,
        imageUrl: img10,
    },
];


function Home() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='Home'>
            <Popup />
            <div className="banner">
                <div className='container'>
                    <div className='row'>
                        <div className="banner-text">
                            <h1>NAGRI <br /> Na Norta</h1>
                            <div className='when-where-block'>
                                <div className='row'>
                                    <div className='main-heading when'>
                                        <h3 className="subheading">Dates</h3>
                                        <p className="clr_change_text">3<sup>rd</sup> to 11<sup>th</sup> Oct. 2024<br />09:00 PM Onwards</p>
                                    </div>
                                    <div className='main-heading where'>
                                        <h3 className="subheading">Venue</h3>
                                        <NavLink to='https://maps.app.goo.gl/15q2u8d7xjE7Yf1eA' target='_blank' className="clr_change_text desktop-only">
                                            Nagri Na Norta, Karnavati University, <br />
                                            Uvarsad-Adalaj Road, Uvarsad, <br />
                                            Gandhinagar, Gujarat 382422
                                        </NavLink>
                                        <NavLink to="https://maps.app.goo.gl/15q2u8d7xjE7Yf1eA" target='_blank' className="clr_change_text mobile-only">
                                            Nagri Na Norta, Karnavati <br />
                                            University, Uvarsad-Adalaj Road,<br />
                                            Uvarsad, Gandhinagar, Gujarat 382422
                                        </NavLink>
                                    </div>
                                </div>
                                <div className='main-heading'>
                                    <NavLink className="common-btn" to="/buy-now" ><BsTicketPerforated /><span>Book Passes</span></NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="banner-image">
                            <img src={BannerImage} alt='banner image' />
                        </div>
                    </div>
                </div>
            </div>

            <section className='section about-section' id='Whatismandli'>
                <div className='container'>
                    <div className='row'>
                        <div className='left'>
                            <div className='main-heading what_is_mandli'>
                                <h2 className="and-fonts">Why <img src={NagriLogoBlack} /></h2>
                                <img className='mobile-only' src={AboutImg} alt="about image" />
                                <p><strong>Nagri Na Norta</strong> aims to foster togetherness by creating a space where all  feel included. They preserve Navratri's cultural heritage while introducing  innovative elements. Every individual is valued at the event, ensuring  everyone feels special. Join us for a unique Navratri celebration blending  tradition and innovation.</p>
                                <div className='main-heading pt-2'>
                                    <NavLink className="common-btn mt-3" to="/about" ><span>Know More</span></NavLink>
                                </div>
                            </div>
                        </div>
                        <div className='right'>
                            <img src={AboutImg} alt="about image" />
                        </div>
                    </div>
                </div>
            </section>


            <section className='section formats-section' id='gallery'>
                <div className='container'>
                    <div className='main-heading what_is_mandli'>
                        <h2 className="and-fonts">Highlights of 2023</h2>
                    </div>
                </div>
                <div className='image-gallry '>
                    <div className='container'>
                        <Swiper
                            effect="coverflow"
                            grabCursor={true}
                            centeredSlides={true}
                            slidesPerView={"auto"}
                            loop={true}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}
                            coverflowEffect={{
                                rotate: 50,
                                stretch: 0,
                                depth: 100,
                                modifier: 1,
                                slideShadows: true,
                            }}
                            pagination={{ clickable: true }}
                            modules={[
                                EffectCoverflow,
                                Pagination,
                                Autoplay
                            ]}
                            className="mySwiper"
                        >
                            {itemsa.map((item, index) => (
                                <SwiperSlide key={item.id}>
                                    <img src={item.imageUrl} alt={`Slide ${index + 1}`} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
                <div className='main-heading mt-3'>
                    <div className='container'>
                        <NavLink className="common-btn gallry_filed mt-2" to="/gallery" ><span>View Gallery</span></NavLink>
                    </div>
                </div>
            </section >

            <FAQs />

            <section className='footer-before-section'>
                <img src={Beforefooter} alt />
            </section>


        </div >
    )
}

export default Home
