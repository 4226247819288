import React, { useEffect } from 'react'

function RefundAndCancellation() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <>
            <section className='Privacy-Policys buyNow'>
                <div className='inner-banner'>
                    <div className="container">
                        <h1>Refund And Cancellation</h1>
                    </div>
                </div>
            </section>

            <div className='TermsandConditions'>
                <section className='section PrivacyPolicy' id='refunt'>
                    <div className='container'>

                        <div className='formats-grid'>
                            <div className='inner-box'>
                                <div className='box-content'>
                                    <p>If the event is cancelled for any reason outside of the organizer's reasonable control (i.e., Bad weather conditions, government-imposed lockdown, riots or any kind of natural calamities).No refund will be issued under any circumstances.</p>
                                    <p>No refund would be issued in following case:</p>
                                    <ul>
                                        <li><p>Pass once booked cannot be cancelled, exchanged or refunded.</p></li>
                                        <li><p>Unauthorized use of this pass is prohibited.</p></li>
                                        <li><p>Unlawful resale (or attempted unlawful resale) of a pass would lead to seizure or cancellation of that pass without refund or other compensation.</p></li>
                                        <li><p>Duplicate passes will not be issued for lost or stolen tickets.</p></li>
                                        <li><p>Organizers hold the right to deny late entry to the event.</p></li>
                                        <li><p>People in an inebriated state may not be allowed entry.</p></li>
                                    </ul>
                                    <p>In case the attendee has made the payment but failed to receive any email and/or SMS confirmation, then chances are that such a payment has failed. Once we have confirmed a failed order, we process refunds on our own. While we process such refunds within 5-10 days, it may take up to 10-12 working days land sometimes more) for your financial institution to credit the amount back to your account.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>

    )
}

export default RefundAndCancellation