import React, { useEffect } from 'react'
import "./Signup.css"
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { getAuthOtp, getUserSignup, getValidateEmailPhone, getVerifyauthOtp } from '../../Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { Crypt } from 'hybrid-crypto-js';
import rotate_image from '../../Images/nagri/rotating-circle.svg';
import NagriLogo from '../../Images/home/nagri-logo.png';
import OTPInput from 'react-otp-input';
import { isValidEmail, isValidPhoneNumber } from '../../Helper/validation';
import { isValidEmailAddress } from '../../Validation/Validation';
import { getIsLogin } from '../../Helper/Token';

const toastStyle = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
}

function Signup() {
  const navigate = useNavigate();

  const [isOtpSend, setIsOtpSend] = useState(false)
  const [otpLess, setOtpLess] = useState(false)
  const [isVerified, setIsVerified] = useState()
  const [showVarified, setShowVarified] = useState(false)
  const [showOtpLess, setShowOtpLess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [emailError, setEmailError] = useState("")

  const [isEmailExist, setIsEmailExist] = useState(false)
  const [isPhoneExist, setIsPhoneExist] = useState(false)

  // Get Is Login
  useEffect(() => {
    if (getIsLogin()) {
      navigate('/profile')
    }
  }, [])

  // Check email or phone no exist or not
  const checkIsExist = (e, data) => {
    const { value } = e.target;
    if (data === "email") {
      axios.post(`${getValidateEmailPhone}?email=${value}`)
        .catch((err) => {
          if (err.response.data.isExist) {
            setIsEmailExist(true)
          } else {
            setIsEmailExist(false)
          }
        })
    } else if (data === "phone") {
      axios.post(`${getValidateEmailPhone}?phone=${value}`)
        .catch((err) => {
          if (err.response.data.isExist) {
            setIsPhoneExist(true)
          } else {
            setIsPhoneExist(false)
          }
        })
    }
  }

  // Timer
  const [timer, setTimer] = useState(30);

  useEffect(() => {
    let intervalId;
    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [timer]);

  // Get Otp
  const [OTP, setOTP] = useState("");
  const handleOTPChange = (otp) => {
    setOTP(otp);
    setErrorShow(true)
    if (signupData.name !== "" && emailError === "" && phoneError === "") {
      if (otp.length === 4) {
        const formData = new FormData()
        formData.append('email', signupData.email)
        formData.append('code', otp)
        axios.post(getVerifyauthOtp, formData)
          .then((res) => {
            setIsLoading(true)
            setShowVarified(true)
            setIsVerified(true)
            setTimeout(() => {
              axios.post(getUserSignup, { ...signupData, phone: parseInt(signupData.phone), code: otp })
                .then((res) => {
                  toast.success(res.data.message, toastStyle);
                  localStorage.setItem("token", res.data.token);
                  navigate('/profile')
                  setIsLoading(false)
                })
                .catch((err) => {
                  toast.error(err.response.data.message, toastStyle);
                  setIsVerified(false)
                  isLoading(false)
                  setOTP('')
                })
            }, 1000)
          }).catch((err) => {
            setIsLoading(false)
            setShowVarified(true)
            setIsVerified(false)
          })
      } else {
        setIsVerified("")
      }
    }
  };

  // Variables
  const [error, setError] = useState(false);
  const [signupData, setSignupData] = useState({
    name: "",
    email: "",
    phone: ""
  })

  // Send Otp
  const [phoneError, setPhoneError] = useState('')
  const [errorShow, setErrorShow] = useState(false)
  const handleSendOtp = (e, data) => {
    setErrorShow(true)
    if (phoneError === "") {
      if (!isVerified) {
        axios.post(`${getValidateEmailPhone}?phone=${signupData.email}`)
          .catch((err) => {
            if (err.response.data.isExist) {
              setIsEmailExist(true)
            } else {
              axios.post(getAuthOtp, { email: signupData.email })
                .then((res) => {
                  setTimer(20);
                  setIsOtpSend(true)
                })
            }
          })
      }
    }
    if (data === "otpLess") {
      setTimeout(() => {
        setOtpLess(true)
        setShowOtpLess(true)
      })
    }
  }

  useEffect(() => {
    const phoneValidation = isValidPhoneNumber(signupData.phone);
    if (signupData.phone === "") {
      setPhoneError('The Phone no. field is mandatory.')
    } else if (!phoneValidation) {
      setPhoneError('please enter 10 digit phone number.')
    } else {
      setPhoneError('')
    }

    if (signupData.email === "") {
      setEmailError("Email field is mandatory");
    } else if (!isValidEmailAddress(signupData.email)) {
      setEmailError("Please Enter a valid Email");
    } else {
      setEmailError("");
    }
  }, [signupData])

  // Check stay Logged in
  const handleChecked = (e, data) => {
    if (data === "otpLess") {
      if (e.target.checked) {
        setOtpLess(true)
        setIsVerified(true)
        setIsOtpSend(false)
      } else {
        setOtpLess(false)
        setIsVerified(false)
        setIsOtpSend(true)
      }
    }
  }

  // get Enterd Signup data
  const handleSignUpData = (e) => {
    setSignupData({
      ...signupData,
      [e.target.name]: e.target.value
    })
  }


  // Signup Data

  const handleSignup = (e) => {
    e.preventDefault();
    const signup = {
      ...signupData,
      phone: parseInt(signupData.phone),
    }
    if (!error) {
      axios.post(getUserSignup, signup)
        .then((res) => {
          toast.success(res.data.message, toastStyle);
          setIsLoading(false)
        })
        .catch((err) => {
          toast.error(err.response.data.message, toastStyle);
        })
    }
  }

  return (
    <div className="Signup Login mandli-bg">
      <div className='rotate-image'>
        <img src={rotate_image} alt="" />
      </div>
      <div className="header-top-area-shape"></div>
      <div className='login-block'>
        <div className='logo'>
          <div id="nav">
            <img src={NagriLogo} alt="Nagri Logo" />
          </div>
        </div>
        <div className='at-form'>
          <div className='login-form'>
            <h3>Register for new account</h3>
            <Form>
              <Form.Group className="form-control" controlId="formBasicEmail">
                <Form.Control type="Text" placeholder="Name" name="name" onChange={(e) => handleSignUpData(e)} />
                {errorShow && <p className='error-msg'>{signupData.name === "" && "Please enter name"}</p>}
              </Form.Group>
              <Form.Group className="form-control otpField" controlId="formBasicEmail">
                <div>
                  <Form.Control type="email" placeholder="Enter email" name="email" onChange={(e) => handleSignUpData(e)} onBlur={(e) => checkIsExist(e, 'email')} />
                  {errorShow && <p className='error-msg'>{emailError}</p>}
                  {isEmailExist && <p className='error-msg'>Email already exist, Please Login!</p>}
                </div>
                <Button className={isOtpSend ? "common-btn disabled m-0" : "common-btn m-0"} onClick={(e) => handleSendOtp(e)} disabled={isEmailExist || isPhoneExist}>Get Otp</Button>
              </Form.Group>
              <Form.Group className="form-control" controlId="formBasicEmail">
                <div>
                  <Form.Control type="number" placeholder="Mobie No." name="phone" onChange={(e) => handleSignUpData(e)} onBlur={(e) => checkIsExist(e, 'phone')} />
                  {errorShow && <p className='error-msg'>{phoneError}</p>}
                  {isPhoneExist && <p className='error-msg'>Phone no. already exist, Please Login!</p>}
                </div>
              </Form.Group>

              {
                isOtpSend &&
                <Form.Group className="form-control otp-inputs" controlId="formBasicPassword">
                  <Form.Label>Enter Otp</Form.Label>
                  <div className='varifyOTP'>
                    <OTPInput
                      className="otp-field"
                      value={OTP}
                      onChange={(e) => handleOTPChange(e)}
                      numInputs={4}
                      renderInput={(props) => <input {...props} disabled={isVerified} />}
                    />
                    {
                      showVarified && OTP.length === 4 && <>
                        {isVerified ?
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 100 100">
                            <path id="checkmark" d="M25 50 L40 65 L75 30" fill="transparent" stroke="#730000" stroke-width="6" stroke-dasharray="100" stroke-dashoffset="100">
                              <animate attributeName="stroke-dashoffset" begin="0s" dur="1s" to="0" fill="freeze" />
                            </path>
                          </svg> :
                          !isVerified ?
                            <svg width="30" height="30" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7 7.9375C7.30469 7.9375 7.5625 8.19531 7.5625 8.5C7.5625 8.82812 7.30469 9.0625 7 9.0625C6.67188 9.0625 6.4375 8.82812 6.4375 8.5C6.4375 8.19531 6.67188 7.9375 7 7.9375ZM7 7C6.78906 7 6.625 6.83594 6.625 6.625V3.25C6.625 3.0625 6.78906 2.875 7 2.875C7.1875 2.875 7.375 3.0625 7.375 3.25V6.625C7.375 6.83594 7.1875 7 7 7ZM12.8125 8.82812C13.0469 9.22656 13.0469 9.69531 12.8125 10.0938C12.5781 10.5156 12.1562 10.75 11.6641 10.75H2.3125C1.82031 10.75 1.39844 10.5156 1.16406 10.0938C0.929688 9.69531 0.929688 9.22656 1.16406 8.82812L5.85156 0.90625C6.08594 0.507812 6.50781 0.25 7 0.25C7.46875 0.273438 7.89062 0.507812 8.125 0.90625L12.8125 8.82812ZM12.1562 9.71875C12.2734 9.55469 12.25 9.36719 12.1562 9.20312L7.46875 1.28125C7.375 1.11719 7.1875 1.02344 7 1C6.78906 1 6.60156 1.11719 6.50781 1.28125L1.82031 9.20312C1.72656 9.36719 1.70312 9.55469 1.82031 9.71875C1.91406 9.90625 2.10156 10 2.3125 10H11.6641C11.875 10 12.0625 9.90625 12.1562 9.71875Z" fill="#730000" />
                            </svg>
                            : ""}
                      </>
                    }
                  </div>

                  <p className='resend-btn'>
                    {
                      !isVerified &&
                      <>
                        {timer > 0
                          ? `Resend code in ${timer} seconds`
                          : <><span onClick={(e) => handleSendOtp(e, "otpLess")}>Resend</span> OTP</>}
                      </>
                    }
                  </p>
                </Form.Group>
              }

              {showOtpLess &&
                <Form.Group className="checkbox" controlId="formBasicCheckbox">
                  <div className='check'>
                    <Form.Check type="checkbox" onChange={(e) => handleChecked(e, "otpLess")} name="tryOtpLess" label="Login without Phone no. verification" />
                  </div>
                </Form.Group>
              }

              <Button variant="contained" type="submit" onClick={(e) => handleSignup(e)} className='primaryBtn common-btn justify-content-center m-0' disabled={!isVerified || !otpLess}>
                Sign Up {isLoading && <div className="loginLoader"></div>}
              </Button>
              <p className='signup-line'>Already have an account? <NavLink to='/login' >Login</NavLink></p>
            </Form>
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  )
}

export default Signup