import React, { useState } from "react";
import "./Formats.css"
import img01 from '../../Images/event/NGR_001.jpeg'
import img02 from '../../Images/event/NGR_002.jpeg'
import img03 from '../../Images/event/NGR_003.jpeg'
import img04 from '../../Images/event/NGR_004.jpeg'
import img05 from '../../Images/event/NGR_005.jpeg'
import img06 from '../../Images/event/NGR_006.jpeg'
import img07 from '../../Images/event/NGR_007.jpeg'
import img08 from '../../Images/event/NGR_008.jpeg'
import img09 from '../../Images/event/NGR_009.jpeg'
import img10 from '../../Images/event/NGR_0010.jpeg'
import img11 from '../../Images/event/NGR_0011.jpeg'
import img12 from '../../Images/event/NGR_0012.jpeg'
import img13 from '../../Images/event/NGR_0013.jpeg'
import img14 from '../../Images/event/NGR_0014.jpeg'
import img15 from '../../Images/event/NGR_0015.jpeg'
import img16 from '../../Images/event/NGR_0016.jpeg'
import img17 from '../../Images/event/NGR_0017.jpeg'
import img18 from '../../Images/event/NGR_0018.jpeg'
import img19 from '../../Images/event/NGR_0019.jpeg'
import img20 from '../../Images/event/NGR_0020.jpeg'
import img21 from '../../Images/event/NGR_0021.jpeg'
import img22 from '../../Images/event/NGR_0022.jpeg'

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import Masonry from "react-masonry-css";

const images = [
    {
        id: 1,
        original: img01,
    },
    {
        id: 2,
        original: img02,
    },
    {
        id: 3,
        original: img03,
    },
    {
        id: 4,
        original: img04,
    },
    {
        id: 5,
        original: img05,
    },
    {
        id: 6,
        original: img06,
    },
    {
        id: 7,
        original: img07,
    },
    {
        id: 8,
        original: img08,
    },
    {
        id: 9,
        original: img09,
    },
    {
        id: 10,
        original: img10,
    },
    {
        id: 11,
        original: img11,
    },
    {
        id: 12,
        original: img12,
    },
    {
        id: 13,
        original: img13,
    },
    {
        id: 14,
        original: img14,
    },
    {
        id: 15,
        original: img15,
    },
    {
        id: 16,
        original: img16,
    },
    {
        id: 17,
        original: img17,
    },
    {
        id: 18,
        original: img18,
    },
    {
        id: 19,
        original: img19,
    },
    {
        id: 20,
        original: img20,
    },
    {
        id: 21,
        original: img21,
    },
    {
        id: 22,
        original: img22,
    },
];

function Formats() {

    Fancybox.bind("[data-fancybox]", {
        // Your custom options
    });

    const breakpointColumnsObj = {
        default: 3,
        1100: 2,
        700: 2,
    };


    return (
        <div className="formate-block">
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
            >
                {images.map((image) => (
                    <div className="galleryColumn" key={image.id}>
                        <a href={image.original} data-fancybox data-caption={image.caption}>
                            <img src={image.original} alt={image.caption} />
                        </a>
                    </div>
                ))}
            </Masonry>
        </div>
        // <div className="formate-block">
        //     {images.map((image) => (
        //         <div className="column" key={image.id}>
        //             <a href={image.original} data-fancybox data-caption={image.caption}>
        //                 <img src={image.original} alt={image.caption} />
        //             </a>
        //         </div>
        //     ))}
        // </div>
    )
}

export default Formats