import React, { useEffect, useState } from 'react'
import './BuyNow.css'
import { Button, Form } from 'react-bootstrap'
import { getTicketsList } from '../../Api'
import axios from 'axios'
import { calculatePriceBreakdown, getToken } from '../../Helper/Token'
import { useNavigate } from 'react-router-dom'
import { BiMinus, BiPlus } from 'react-icons/bi'
import garbaImg from '../../Images/Mandli/about.png'


// const tickets = [
//     {
//         "_id": "65148b2ffed5df8c5731e73c",
//         "TicketTitle": "Season Pass",
//         "isCombo": true,
//         "TicketType": "64e4c0cb6236f9b55b36e1db",
//         "Description": "3rd to 11rd October",
//         "Startsale": "2023-09-27T20:03:00.000Z",
//         "Endsale": "2023-10-15T18:29:00.000Z",
//         "Price": 4500,
//         "Capacity": 5000,
//         "SKU": "All9Days",
//         "flag": 1,
//         "createdAt": "2023-09-27T20:06:07.165Z",
//         "updatedAt": "2024-09-22T15:47:42.684Z",
//         "PhaseData": [
//             {
//                 "PhaseName": "Early Bird"
//             }
//         ]
//     },
//     {
//         "_id": "65148dcfa66a37e2d0be9cf3",
//         "TicketTitle": "Pehlu Nortu ",
//         "isCombo": false,
//         "TicketType": "64e4c0cb6236f9b55b36e1db",
//         "Description": "3rd October",
//         "Startsale": "2023-09-27T20:16:00.000Z",
//         "Endsale": "2023-10-15T18:29:00.000Z",
//         "Price": 500,
//         "Capacity": 5000,
//         "SKU": "Day1",
//         "flag": 1,
//         "createdAt": "2023-09-27T20:17:19.247Z",
//         "updatedAt": "2024-09-22T15:47:43.479Z",
//         "PhaseData": [
//             {
//                 "PhaseName": "Early Bird"
//             }
//         ],
//         "ticketNameData": [
//             {
//                 "ticketDay": "Day 1",
//                 "ticketName": "Pehlu Nortu"
//             }
//         ]
//     },
//     {
//         "_id": "65148e0354b6a36256b9b92f",
//         "TicketTitle": "Beeju Nortu",
//         "isCombo": false,
//         "TicketType": "64e4c0cb6236f9b55b36e1db",
//         "Description": "4th October",
//         "Startsale": "2023-09-27T20:17:00.000Z",
//         "Endsale": "2023-10-17T06:30:00.000Z",
//         "Price": 500,
//         "Capacity": 5000,
//         "SKU": "Day2",
//         "flag": 1,
//         "createdAt": "2023-09-27T20:18:11.753Z",
//         "updatedAt": "2024-09-22T15:47:44.281Z",
//         "PhaseData": [
//             {
//                 "PhaseName": "Early Bird"
//             }
//         ],
//         "ticketNameData": [
//             {
//                 "ticketDay": "Day 2",
//                 "ticketName": "Beeju Nortu"
//             }
//         ]
//     },
//     {
//         "_id": "65148e5554b6a36256b9b935",
//         "TicketTitle": "Treeju Nortu",
//         "isCombo": false,
//         "TicketType": "64e4c0cb6236f9b55b36e1db",
//         "Description": "5th October",
//         "Startsale": "2023-09-27T20:19:00.000Z",
//         "Endsale": "2023-10-17T18:30:00.000Z",
//         "Price": 500,
//         "Capacity": 5000,
//         "SKU": "Day3",
//         "flag": 1,
//         "createdAt": "2023-09-27T20:19:33.539Z",
//         "updatedAt": "2024-09-22T15:47:44.886Z",
//         "PhaseData": [
//             {
//                 "PhaseName": "Early Bird"
//             }
//         ],
//         "ticketNameData": [
//             {
//                 "ticketDay": "Day 3",
//                 "ticketName": "Treeju Nortu"
//             }
//         ]
//     },
//     {
//         "_id": "65148e8797d5af4fd087f474",
//         "TicketTitle": "Chothu Nortu",
//         "isCombo": false,
//         "TicketType": "64e4c0cb6236f9b55b36e1db",
//         "Description": "6th October",
//         "Startsale": "2023-09-27T20:19:00.000Z",
//         "Endsale": "2023-10-18T18:30:00.000Z",
//         "Price": 500,
//         "Capacity": 5000,
//         "SKU": "Day4",
//         "flag": 1,
//         "createdAt": "2023-09-27T20:20:23.311Z",
//         "updatedAt": "2024-09-22T15:47:46.462Z",
//         "PhaseData": [
//             {
//                 "PhaseName": "Early Bird"
//             }
//         ],
//         "ticketNameData": [
//             {
//                 "ticketDay": "Day 4",
//                 "ticketName": "Chothu Nortu"
//             }
//         ]
//     },
//     {
//         "_id": "65148ebd97d5af4fd087f47a",
//         "TicketTitle": "Panchmu Nortu",
//         "isCombo": false,
//         "TicketType": "64e4c0cb6236f9b55b36e1db",
//         "Description": "7th October",
//         "Startsale": "2023-09-27T20:20:00.000Z",
//         "Endsale": "2023-10-19T18:30:00.000Z",
//         "Price": 500,
//         "Capacity": 5000,
//         "SKU": "Day5",
//         "flag": 1,
//         "createdAt": "2023-09-27T20:21:17.115Z",
//         "updatedAt": "2024-09-22T15:47:47.279Z",
//         "PhaseData": [
//             {
//                 "PhaseName": "Early Bird"
//             }
//         ],
//         "ticketNameData": [
//             {
//                 "ticketDay": "Day 5",
//                 "ticketName": "Panchmu Nortu"
//             }
//         ]
//     },
//     {
//         "_id": "65148f0be2a58b8283b9343e",
//         "TicketTitle": "Chhathu Nortu",
//         "isCombo": false,
//         "TicketType": "64e4c0cb6236f9b55b36e1db",
//         "Description": "8th October",
//         "Startsale": "2023-09-27T20:21:00.000Z",
//         "Endsale": "2023-10-20T18:30:00.000Z",
//         "Price": 500,
//         "Capacity": 5000,
//         "SKU": "Day6",
//         "flag": 1,
//         "createdAt": "2023-09-27T20:22:35.956Z",
//         "updatedAt": "2024-09-22T15:47:48.647Z",
//         "PhaseData": [
//             {
//                 "PhaseName": "Early Bird"
//             }
//         ],
//         "ticketNameData": [
//             {
//                 "ticketDay": "Day 6",
//                 "ticketName": "Chhathu Nortu"
//             }
//         ]
//     },
//     {
//         "_id": "65148f37e2a58b8283b93444",
//         "TicketTitle": "Satmu Nortu",
//         "isCombo": false,
//         "TicketType": "64e4c0cb6236f9b55b36e1db",
//         "Description": "9th October",
//         "Startsale": "2023-09-27T20:23:00.000Z",
//         "Endsale": "2023-10-21T18:30:00.000Z",
//         "Price": 500,
//         "Capacity": 5000,
//         "SKU": "Day7",
//         "flag": 1,
//         "createdAt": "2023-09-27T20:23:19.079Z",
//         "updatedAt": "2024-09-22T15:47:53.584Z",
//         "PhaseData": [
//             {
//                 "PhaseName": "Early Bird"
//             }
//         ],
//         "ticketNameData": [
//             {
//                 "ticketDay": "Day 7",
//                 "ticketName": "Satmu Nortu"
//             }
//         ]
//     },
//     {
//         "_id": "65148f67e2a58b8283b9344a",
//         "TicketTitle": "Aathmu Nortu",
//         "isCombo": false,
//         "TicketType": "64e4c0cb6236f9b55b36e1db",
//         "Description": "10th October",
//         "Startsale": "2023-09-27T20:23:00.000Z",
//         "Endsale": "2023-10-22T18:30:00.000Z",
//         "Price": 500,
//         "Capacity": 5000,
//         "SKU": "Day8",
//         "flag": 1,
//         "createdAt": "2023-09-27T20:24:07.868Z",
//         "updatedAt": "2024-09-22T15:47:54.583Z",
//         "PhaseData": [
//             {
//                 "PhaseName": "Early Bird"
//             }
//         ],
//         "ticketNameData": [
//             {
//                 "ticketDay": "Day 8",
//                 "ticketName": "Aathmu Nortu"
//             }
//         ]
//     },
//     {
//         "_id": "65148f97e2a58b8283b93450",
//         "TicketTitle": "Navmu Nortu",
//         "isCombo": false,
//         "TicketType": "64e4c0cb6236f9b55b36e1db",
//         "Description": "11th October",
//         "Startsale": "2023-09-27T20:24:00.000Z",
//         "Endsale": "2023-10-23T18:30:00.000Z",
//         "Price": 500,
//         "Capacity": 5000,
//         "SKU": "Day9",
//         "flag": 1,
//         "createdAt": "2023-09-27T20:24:55.190Z",
//         "updatedAt": "2024-09-22T15:47:55.451Z",
//         "PhaseData": [
//             {
//                 "PhaseName": "Early Bird"
//             }
//         ],
//         "ticketNameData": [
//             {
//                 "ticketDay": "Day 9",
//                 "ticketName": "Navmu Nortu"
//             }
//         ]
//     }
// ]

function BuyNow() {
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    // Variables
    const [tickets, setTickets] = useState([]);
    const [selected, setSelected] = useState({});
    const [purchaseData, setPurchaseData] = useState({
        selectedTickets: [],
        purchaseTicketsData: [],
        grandTotal: 0,
        totalQty: 0
    });

    // Get details from localstorage
    useEffect(() => {
        const purchaseDataFromStorage = localStorage.getItem('purchaseTicketsData');
        if (purchaseDataFromStorage) {
            const parsedData = JSON.parse(purchaseDataFromStorage);
            setPurchaseData({
                ...purchaseData,
                selectedTickets: parsedData.selectedTickets,
                purchaseTicketsData: parsedData.purchaseTicketsData,
                grandTotal: parsedData.grandTotal,
                totalQty: parsedData.totalQty
            });
            const result = parsedData.selectedTickets.reduce((accumulator, currentObject) => {
                const id = currentObject.id;
                const quantity = parseInt(currentObject.quantity, 10);

                if (id && !isNaN(quantity)) {
                    accumulator[id] = quantity;
                }

                return accumulator;
            }, {});
            setSelected(result)
        }
    }, []);

    // Get All Tickets List
    const fetchTickets = () => {
        getToken();
        axios.get(`${getTicketsList}`)
            .then((res) => {
                if (res.data.result.docs) {
                    res.data.result.docs.forEach((ele) => {
                        if (!ele.isCombo && ele.TicketTitle) {
                            const data = []
                            const parts = ele.TicketTitle.split(':');
                            const ticketDay = parts[0]?.trim();
                            const ticketName = parts[1]?.trim();
                            const ticketNameData = {
                                ticketDay,
                                ticketName,
                            };
                            data.push(ticketNameData)
                            ele.ticketNameData = data
                        }
                    });
                    setTickets(res.data.result.docs);
                }
            })
            .catch((error) => {
                console.error("Error fetching tickets:", error);
            });
    };

    useEffect(() => {
        fetchTickets();
    }, []);

    // Handle Quantity Increase Decrease
    const handleQuantityChange = (_id, quantity, e) => {
        e.preventDefault();

        setSelected((prevselected) => {
            const updatedselected = {
                ...prevselected,
                [_id]: parseInt(quantity, 10) >= 0 ? parseInt(quantity, 10) : 0,
            };

            if (updatedselected[_id] === 0) {
                delete updatedselected[_id];
            }

            return updatedselected;
        });
    };

    // Calculate the grand grand total and Tickets
    useEffect(() => {
        let totalQty = 0;
        let grandTotal = 0;
        const selectedTickets = [];

        for (const id in selected) {
            const quantity = selected[id];
            const ticket = tickets.find((t) => t._id === id);

            if (ticket) {
                totalQty += quantity;
                const ticketPrice = (ticket.SalePrice && ticket.SalePrice > 0) ? ticket.SalePrice : ticket.Price;
                const amount = ticketPrice * quantity;
                grandTotal += amount;

                selectedTickets.push({
                    id: ticket._id,
                    quantity: quantity
                });
            }
        }

        const purchaseTicketsData = selectedTickets.map((ticket) => ({
            _id: ticket.id,
            TicketTitle: tickets.find((t) => t._id === ticket.id)?.TicketTitle,
            Description: tickets.find((t) => t._id === ticket.id)?.Description || '',
            Price: tickets.find((t) => t._id === ticket.id)?.Price || 0,
            SalePrice: tickets.find((t) => t._id === ticket.id)?.SalePrice || 0,
            isCombo: tickets.find((t) => t._id === ticket.id)?.isCombo || false,
            quantity: parseInt(ticket.quantity, 10),
            Amount: tickets.find((t) => t._id === ticket.id)?.Price * parseInt(ticket.quantity, 10) || 0,
            PhaseName: tickets.find((t) => t._id === ticket.id)?.PhaseData[0].PhaseName || ""
        }));

        setPurchaseData({
            ...purchaseData,
            totalQty,
            grandTotal,
            selectedTickets,
            purchaseTicketsData
        });

        setTimeout(() => {
            localStorage.setItem('purchaseTicketsData', JSON.stringify({
                totalQty,
                grandTotal,
                selectedTickets,
                purchaseTicketsData
            }))
        }, 1000)
    }, [selected, tickets]);

    // Proceed to Checkout
    const handleProceed = (e) => {
        e.preventDefault();

        if (purchaseData.selectedTickets.length > 0) {
            localStorage.setItem('purchaseTicketsData', JSON.stringify(purchaseData))
            navigate("/checkout")
        }
    }

    const [isStarted, setIsStarted] = useState(false);
    useEffect(() => {
        const today = new Date();
        const targetDate = new Date('2024-10-03');

        if (today >= targetDate) {
            setIsStarted(true);
        }
    }, []);

    return (
        <>
            <section className='buyNow'>
                <div className='inner-banner'>
                    <div className="container">
                        <h1>Passes</h1>
                    </div>
                </div>
                <div className="main">
                    <div className="container">
                        <div className='ticketBookingBlock'>
                            <div className='ticketsBlock'>
                                {
                                    tickets.length <= 0 && <div className='orderNotFound comingSoon'>
                                        <div className='bookNow'>
                                            <h1>OOPS!</h1>
                                            <h3>No Passes are Available.</h3>
                                        </div>
                                        <img src={garbaImg} alt="" />
                                    </div>
                                }
                                {tickets.map((ticket) => (
                                    <div
                                        className={
                                            selected[ticket._id] > 0 && ticket.isCombo
                                                ? 'ticket combo selected'
                                                : ticket.isCombo
                                                    ? 'ticket combo'
                                                    : selected[ticket._id] > 0
                                                        ? 'ticket selected'
                                                        : 'ticket'
                                        }
                                        key={ticket._id}>
                                        <div className='border'></div>
                                        {/* <div className='dayName'>
                                            <h3>{ticket.isCombo ? "all 9 days" : ticket.ticketNameData[0].ticketDay}</h3>
                                        </div> */}
                                        <div className='ticketData'>
                                            <div className='name'>
                                                <h2>{ticket.TicketTitle} <span>{ticket.Description}</span></h2>
                                            </div>
                                            <div className='booking'>
                                                <div className="price">
                                                    <h3>
                                                        {ticket.SalePrice && ticket.SalePrice > 0 ? (
                                                            <>
                                                                <span className='strikePrice'>
                                                                    &#8377;{ticket.Price}
                                                                </span>
                                                                &#8377;{ticket.SalePrice}
                                                            </>
                                                        ) : (
                                                            <>&#8377;{ticket.Price}</>
                                                        )}
                                                        <span>({ticket.PhaseData[0].PhaseName})</span>
                                                    </h3>
                                                    <p>(Price incl. of 18% GST)</p>
                                                </div>
                                                {
                                                    ticket.flag === 4 ?
                                                        <p className='soldOut'>Sold Out</p> :
                                                        <div className='quantity'>
                                                            <div className='action'>
                                                                <button className='qty-btn minus' onClick={(e) => handleQuantityChange(ticket._id, (selected[ticket._id] || 0) - 1, e)} > <BiMinus /> </button>
                                                                <span className='qty'>{selected[ticket._id] || 0}</span>
                                                                <button className='qty-btn plus' onClick={(e) => handleQuantityChange(ticket._id, (selected[ticket._id] || 0) + 1, e)} > <BiPlus /> </button>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='summary'>
                                <div className='total'>
                                    <h2 className={purchaseData.totalQty > 0 ? "" : "empty"}><b>Qty :</b> <span>{purchaseData.totalQty}</span></h2>
                                    <h2 className={purchaseData.totalQty > 0 ? "" : "empty"}><b>Total :</b> <span>&#8377; {purchaseData.grandTotal}</span></h2>
                                </div>
                                <div className='checkout'>
                                    <Button className='common-btn justify-content-center' disabled={purchaseData.totalQty < 1} onClick={(e) => handleProceed(e)}>Proceed to Checkout</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BuyNow