import React, { useState } from 'react';
import { IoChevronDownOutline } from "react-icons/io5";

function FAQs() {
    const faqs = [
        {
            title: "Where is Nagri Na Norta held?",
            description: "ou can find the location details on the official event website or social media pages."
        },
        {
            title: "How can I purchase tickets for Nagri Na Norta?",
            description: "Tickets are usually sold online through official platforms or authorized ticket vendors. Check the event website for details. Offline it will be available soon."
        },
        {
            title: "Are there any age restrictions for attending Nagri Na Norta?",
            description: "Age restrictions may vary, but generally, children of a certain age are allowed to attend with adult supervision. Check the event guidelines for specific information."
        },
        {
            title: "What kind of music and entertainment can I expect at Nagri Na Norta?",
            description: "It's a Cept style garba"
        },
        {
            title: "What is the dress code for Nagri Na Norta?",
            description: "Traditional Indian attire is Mandate, such as chaniya choli for women and kurta dhoti or sherwani for Men."
        },
        {
            title: "Are there any food and drink options available at the venue?",
            description: "Yes, there are food stalls and beverage vendors offering a variety of options to satisfy your cravings."
        },
        {
            title: "Are there any safety measures in place at Nagri Na Norta?",
            description: "We have typically implement every safety measures, including security personnel and medical facilities, to ensure the well-being of attendees."
        },
        {
            title: "Can I bring my own food and drinks to Nagri Na Norta?",
            description: "It is not allowed.Check the official guidelines for specific information."
        },
        {
            title: "Is there parking available at the venue?",
            description: "Yes, Parking arrangements is available."
        },
        {
            title: "How can I stay updated about the latest news and announcements regarding Nagri Na Norta?",
            description: "Follow the official event website, social media channels, or subscribe to their newsletter to receive the most current information."
        }
    ]

    const [expandedItems, setExpandedItems] = useState(Array(faqs.length).fill(false));

    const toggleAccordion = (index) => {
        setExpandedItems((prevExpandedItems) =>
            prevExpandedItems.map((item, i) => (i === index ? !item : false))
        );
    };

    return (
        <section className='section faqs-section p-100'>
            <div className='container'>
                <div className="main-heading what_is_mandli">
                    <h2 className="and-fonts">Frequently Asked Questions</h2>
                </div>
                <div className="faqs-block">
                    <div className="faqs-custom-section">
                        <div className="accordion-faqs">
                            {faqs.map((faq, index) => (
                                <div className="accordion-item" key={index}>
                                    <button
                                        id={`accordion-button-${index}`}
                                        className={`${expandedItems[index] ? 'expanded' : ''}`}
                                        onClick={() => toggleAccordion(index)}
                                    >
                                        <h2 className="leading-normal accordion-title">{faq.title}</h2>
                                        <span className="icon" aria-hidden="true">
                                            <IoChevronDownOutline />
                                        </span>
                                    </button>
                                    <div className={`accordion-content ${expandedItems[index] ? 'expanded' : ''}`}>
                                        <p>{faq.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FAQs