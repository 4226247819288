import React, { useEffect, useState } from 'react';
import './Tickets.css'
import { CiSearch } from 'react-icons/ci';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import axios from 'axios';
import { getAdminToken } from '../../Helper/Token';
import { getAdminCreateTicket, getAdminEditTicket, getEnableDisableTicket, getPhaseList, getTicketsListAdmin } from '../../Api';
import { Button, IconButton, Switch } from '@mui/material';
import { Col, Modal, Pagination, Row, Text, useModal } from '@nextui-org/react';
import Form from 'react-bootstrap/Form';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const toastStyle = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
}


function Tickets() {
  // Search
  const [search, setSearch] = useState("")
  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  // Pagination
  const [pageState, setPageState] = useState({
    total: 0,
    totalPages: 0,
    page: 1,
    pageSize: 10
  })
  const handlePageChange = (selectedPage) => {
    setPageState((prevState) => ({ ...prevState, page: selectedPage }));
  };
  const ResultInfo = () => {
    const start = (pageState.page - 1) * pageState.pageSize + 1;
    const end = Math.min(start + pageState.pageSize - 1, pageState.total);

    return (
      <div className="result-info">
        Showing <span>{start}-{end}</span> of <span>{pageState.total}</span> Tickets
      </div>
    );
  };
  const handlePageSizeChange = (newPageSize) => {
    setPageState((prevState) => ({
      ...prevState,
      page: 1,
      pageSize: newPageSize,
    }));
  };

  // Get All Tickets List
  const [rows, setRows] = useState([])
  const fetchTickets = () => {
    getAdminToken();
    axios.get(`${getTicketsListAdmin}?search=${search}&page=${pageState.page}&limit=${pageState.pageSize}`)
      .then((res) => {
        const json = res.data.result;
        setPageState((prevState) => ({ ...prevState, total: json.totalDocs, totalPages: json.totalPages }));

        setRows(res.data.result.docs)
      })
  }
  useEffect(() => {
    fetchTickets()
  }, [search, pageState.page, pageState.pageSize])


  // Ticket Enable Disab
  const handleEnableDisable = (e, status, id) => {
    e.preventDefault()
    let newStatus = {
      flag: 0
    };
    if (status === 1) {
      newStatus.flag = 3;
    } else if (status === 3) {
      newStatus.flag = 1;
    }

    getAdminToken();
    axios.put(`${getEnableDisableTicket}/${id}`, newStatus)
      .then((res) => {
        toast.success(res.data.message, toastStyle);
        fetchTickets();
      })
      .catch((err) => {
        toast.error(err.response.data.message, toastStyle);
      })
  }

  const handleSoldOut = (e, status, id) => {
    let newStatus = {
      flag: 0
    };
    if (status === 1) {
      newStatus.flag = 4;
    } else if (status === 4) {
      newStatus.flag = 1;
    }

    getAdminToken();
    axios.put(`${getEnableDisableTicket}/${id}`, newStatus)
      .then((res) => {
        toast.success(res.data.message, toastStyle);
        setTicketSoldOutPopupVisible(false)
        fetchTickets();
      })
      .catch((err) => {
        toast.error(err.response.data.message, toastStyle);
      })
  }


  // Add Edit Tickets Popup
  const { setVisible: setProductEditModalVisible, bindings: addTicketModalBindings } = useModal();
  const { setVisible: setTicketEditModalVisible, bindings: editTicketModalBindings } = useModal();

  const openAdd = () => setProductEditModalVisible(true)
  const closeAdd = () => setProductEditModalVisible(false)

  const openEdit = () => setTicketEditModalVisible(true)
  const closeEdit = (e) => {
    e.preventDefault();
    setTicketEditModalVisible(false)
    setEditData({})
  }

  // Phase Options
  const [options, setOptions] = useState([])
  const fetchAllPhase = () => {
    axios.get(getPhaseList)
      .then((res) => {
        res.data.result.map((ele) => {
          ele.value = ele._id;
          ele.label = ele.PhaseName
        })
        setOptions(res.data.result)
      })
  }
  useEffect(() => {
    fetchAllPhase();
  }, [])

  // Get Selected Phase
  const [phaseValue, setPhaseValue] = useState([]);
  const handlePhase = (e, data) => {
    const tempPhase = options.find((ele) => {
      return ele._id === e.target.value
    })
    setPhaseValue(tempPhase)
    setNewData({
      ...newData,
      TicketType: e.target.value
    })
    if (data === "edit") {
      setEditData({
        ...editData,
        TicketType: e.target.value
      })
    }
  }

  // Add new ticket
  const [newData, setNewData] = useState({
    TicketTitle: "",
    TicketType: "",
    TicketDescription: "",
    Startsale: "",
    Endsale: "",
    Price: "",
    SalePrice: "",
    Capacity: "",
    SKU: "",
    isCombo: false
  })

  const handleChange = (e, data) => {
    const { name, value, checked } = e.target;
    setNewData({
      ...newData,
      [name]: value
    })
    if (data === "date") {
      setNewData({
        ...newData,
        [name]: new Date(value).toISOString()
      })
    }
    if (data === "isCombo") {
      setNewData({
        ...newData,
        isCombo: !newData.isCombo
      })
    }
  }

  const handleCreateTicket = (e) => {
    e.preventDefault();
    getAdminToken();
    axios.post(getAdminCreateTicket, newData)
      .then((res) => {
        closeAdd();
        setPhaseValue([]);
        toast.success(res.data.message, toastStyle);
        fetchTickets();
      })
      .catch((err) => {
        toast.error(err.response.data.message, toastStyle);
      })
  }

  // Edit Ticket
  const [editData, setEditData] = useState({})

  const handleEditChange = (e, data) => {
    const { name, value, checked } = e.target;
    setEditData({
      ...editData,
      [name]: value
    })
    if (data === "dateS") {
      setEditData({
        ...editData,
        [name]: new Date(value).toISOString(),
        StartsaleFormated: value
      })
    }
    if (data === "dateE") {
      setEditData({
        ...editData,
        [name]: new Date(value).toISOString(),
        EndsaleFormated: value
      })
    }
    if (data === "desc") {
      setEditData({
        ...editData,
        [name]: value,
        Description: value
      })
    }
    if (name === "Price") {
      setEditData({
        ...editData,
        [name]: Number(value)
      })
    }
    if (name === "SalePrice") {
      setEditData({
        ...editData,
        [name]: Number(value)
      })
    }
    if (data === "isCombo") {
      setEditData({
        ...editData,
        isCombo: !editData.isCombo
      })
    }
  }

  const handleEditClick = (e, data) => {
    const phase = options.find((ele) => ele.value === data.TicketType)
    setPhaseValue(phase)

    const date = new Date(data.Startsale);
    const StartsaleDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

    const dateEnd = new Date(data.Endsale);
    const EndsaleDate = `${dateEnd.getFullYear()}-${(dateEnd.getMonth() + 1).toString().padStart(2, '0')}-${dateEnd.getDate().toString().padStart(2, '0')}T${dateEnd.getHours().toString().padStart(2, '0')}:${dateEnd.getMinutes().toString().padStart(2, '0')}`;

    setEditData({
      ...editData,
      TicketTitle: data.TicketTitle,
      TicketType: data.TicketType,
      TicketDescription: data.Description,
      Description: data.Description,
      Startsale: data.Startsale,
      Endsale: data.Endsale,
      Price: Number(data.Price),
      SalePrice: Number(data.SalePrice),
      Capacity: data.Capacity,
      SKU: data.SKU,
      StartsaleFormated: StartsaleDate,
      EndsaleFormated: EndsaleDate,
      isCombo: data.isCombo,
      _id: data._id
    });

    openEdit();
  }

  const handleEditTicket = (e) => {
    e.preventDefault();
    getAdminToken();
    axios.put(`${getAdminEditTicket}/${editData._id}`, editData)
      .then((res) => {
        closeEdit(e);
        fetchTickets();
        setPhaseValue([])
        toast.success(res.data.message, toastStyle);
      })
      .catch((err) => {
        toast.error(err.response.data.message, toastStyle);
      })
  }

  // Sold Out Popup
  const { setVisible: setTicketSoldOutPopupVisible, bindings: ticketSoldOutPopupBindings } = useModal();

  const [soldOutTicketData, setSoldOutTicketData] = useState()
  const handleOpenEnable = (e) => {
    setSoldOutTicketData(e)
    setTicketSoldOutPopupVisible(true)
  }

  return (
    <>
      <section className='adminDashboard'>
        <div className="dashboard-right-header fr05s-usr-quotsn05s">
          <div className="left-text">
            <h4>Tickets </h4>
            <p className="m-0">Here is all Tickets
            </p>
          </div>
        </div>

        <div className='filter-Search'>
          <div className='filter-block'>
            <div className='qutrowftr08s'>
              <div className='create-block'>
                <Button className='usdercmntbtn common-btn' onClick={openAdd}><PostAddIcon />Create Ticket</Button>
              </div>
            </div>
          </div>
          <div className="search-box form-item position-relative">
            <Form.Control type="text" placeholder="Search.." name="seach" className="search-input w-100" onChange={(e) => handleSearch(e)} />
            <CiSearch />
          </div>
        </div>

        <div className="ticketsContainer">
          <div className='ticketBlock ticketHeader'>
            <div className='ticketName'>
              <p>Ticket Title / Description</p>
            </div>
            <div className='price'>
              <p>Price</p>
            </div>
            <div className='price'>
              <p>Sale Price</p>
            </div>
            <div className='enableDisable'>
              <p>Enabel / Disable</p>
            </div>
            <div className='enableDisable'>
              <p>Is Sold Out</p>
            </div>
            <div className='action'>
              Edit
            </div>
          </div>
          {rows.map((row) => (
            <div className='ticketBlock'>
              <div className='ticketName'>
                <h1>{row.TicketTitle} ({row.PhaseData[0].PhaseName})</h1>
                <p>{row.Description}</p>
              </div>
              <div className='price'>
                <p>&#8377;{row.Price}</p>
              </div>
              <div className='price'>
                <p>{row.SalePrice ? <>
                  &#8377;{row.SalePrice}
                </> : 'NA'}</p>
              </div>
              <div className='enableDisable'>
                <Switch defaultSelected color="success" checked={row.flag === 1} id={row._id} onClick={(e) => handleEnableDisable(e, row.flag, row._id)}></Switch>
              </div>
              <div className='enableDisable'>
                <Switch defaultSelected color="success" checked={row.flag === 4} id={row._id} onClick={() => handleOpenEnable(row)} ></Switch>
              </div>
              <div className='action'>
                <IconButton aria-label="add an alarm" onClick={(e) => handleEditClick(e, row)} className='dyFlextIcon'>
                  <ModeEditOutlineOutlinedIcon />
                </IconButton>
              </div>
            </div>
          ))}
        </div>
        <div className='pagination-block'>
          <ResultInfo />
          <div className='customPagination'>
            <div className="page-size-select">
              Page Size:{" "}
              <select
                value={pageState.pageSize}
                onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <Pagination
              loop
              color="success"
              total={pageState.totalPages}
              initialPage={1}
              boundaries={1}
              siblings={0}
              onChange={(page) => handlePageChange(page)}
              currentPage={pageState.page}
            />
          </div>
        </div>
      </section>

      {/* Add Ticket Popup */}
      <Modal className="ticketsModeloG" scroll width="600px" closeButton aria-labelledby="modal-title" aria-describedby="modal-description" {...addTicketModalBindings} >
        <Modal.Header justify='start'>
          <Text id="modal-title" size={18}>
            Add New Ticket
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Row className="mandli-row mb-3 gap-3">
            <Form.Group as={Col} className="form-control-product" controlId="formBasicImage">
              <Form.Label>Ticket Title</Form.Label>
              <Form.Control type="text" placeholder="Ticket Title" name="TicketTitle" onChange={(e) => handleChange(e)} />
            </Form.Group>
          </Row>
          <Row className="mandli-row mb-3 gap-3 checkBox">
            <Form.Group as={Col} className="form-control-product" controlId="formBasicImage">
              <Form.Label>Is Season Pass</Form.Label>
              <Switch defaultSelected color="success" name="isCombo" checked={newData.isCombo} onClick={(e) => handleChange(e, "isCombo")} />
            </Form.Group>
          </Row>
          <Row className="mandli-row mb-3 gap-3">
            <Form.Group as={Col} controlId="form-control-product">
              <Form.Label>Ticket Type</Form.Label>
              <Form.Select aria-label="Default select example" value={phaseValue?._id} onChange={(e) => handlePhase(e)}>
                <option value="">Select Phase</option>
                {
                  options.map((ele) => {
                    return <option value={ele._id}>{ele.PhaseName}</option>
                  })
                }
              </Form.Select>
            </Form.Group>
          </Row>

          <Row className="mandli-row mb-3 gap-3">
            <Form.Group as={Col} controlId="form-control-product">
              <Form.Label>Ticket Price</Form.Label>
              <Form.Control type="number" placeholder="Ticket Price" name="Price" onChange={(e) => handleChange(e)} />
            </Form.Group>

            <Form.Group as={Col} controlId="form-control-product">
              <Form.Label>Ticket Sale Price</Form.Label>
              <Form.Control type="number" placeholder="Ticket Sale Price" name="SalePrice" onChange={(e) => handleChange(e)} />
            </Form.Group>
          </Row>

          <Row className="mandli-row mb-3 gap-3">
            <Form.Group as={Col} controlId="form-control-product">
              <Form.Label>Ticket Description</Form.Label>
              <Form.Control as="textarea" placeholder='Ticket Description' rows={3} name='TicketDescription' onChange={(e) => handleChange(e)} />
            </Form.Group>
          </Row>
          <Row className="mandli-row mb-3 gap-3">
            <Form.Group as={Col} controlId="form-control-product">
              <Form.Label>Start Sale Date</Form.Label>
              <Form.Control type="datetime-local" placeholder="Start Sale Date" name="Startsale" onChange={(e) => handleChange(e, "date")} />
            </Form.Group>

            <Form.Group as={Col} controlId="form-control-product">
              <Form.Label>End Sale Date</Form.Label>
              <Form.Control type="datetime-local" placeholder="End Sale Date" name="Endsale" onChange={(e) => handleChange(e, "date")} />
            </Form.Group>
          </Row>
          <Row className="mandli-row mb-3 gap-3">
            <Form.Group as={Col} controlId="form-control-product">
              <Form.Label>Capacity</Form.Label>
              <Form.Control type="number" placeholder="Capacity" name="Capacity" onChange={(e) => handleChange(e)} />
            </Form.Group>

            <Form.Group as={Col} controlId="form-control-product">
              <Form.Label>SKU</Form.Label>
              <Form.Control type="text" placeholder="SKU" name="SKU" onChange={(e) => handleChange(e)} />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat className='modelClose' onClick={() => closeAdd()}>
            Cancel
          </Button>
          <Button auto className='modelSubmit common-btn' onClick={(e) => handleCreateTicket(e)}>
            Add Ticket
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Ticket Popup */}
      <Modal className="ticketsModeloG" scroll width="600px" closeButton aria-labelledby="modal-title" aria-describedby="modal-description" {...editTicketModalBindings} >
        <Modal.Header justify='start'>
          <Text id="modal-title" size={18}>
            Edit Ticket
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Row className="mandli-row mb-3 gap-3">
            <Form.Group as={Col} className="form-control-product" controlId="formBasicImage">
              <Form.Label>Ticket Title</Form.Label>
              <Form.Control type="text" value={editData.TicketTitle} placeholder="Ticket Title" name="TicketTitle" onChange={(e) => handleEditChange(e)} />
            </Form.Group>
          </Row>
          <Row className="mandli-row mb-3 gap-3 checkBox">
            <Form.Group as={Col} className="form-control-product" controlId="formBasicImage">
              <Form.Label>Is Season Pass</Form.Label>
              <Switch defaultSelected color="success" name="isCombo" checked={editData.isCombo} onChange={(e) => handleEditChange(e, "isCombo")} />
            </Form.Group>
          </Row>
          <Row className="mandli-row mb-3 gap-3">
            <Form.Group as={Col} controlId="form-control-product">
              <Form.Label>Ticket Type</Form.Label>
              <Form.Select aria-label="Default select example" disabled value={phaseValue._id}>
                <option value="">Select Phase</option>
                {
                  options.map((ele) => {
                    return <option value={ele._id}>{ele.PhaseName}</option>
                  })
                }
              </Form.Select>
            </Form.Group>
          </Row>

          <Row className="mandli-row mb-3 gap-3">
            <Form.Group as={Col} controlId="form-control-product">
              <Form.Label>Ticket Price</Form.Label>
              <Form.Control type="number" placeholder="Ticket Price" value={editData.Price} name="Price" onChange={(e) => handleEditChange(e)} />
            </Form.Group>

            <Form.Group as={Col} controlId="form-control-product">
              <Form.Label>Ticket Sale Price</Form.Label>
              <Form.Control type="number" placeholder="Ticket Sale Price" name="SalePrice" value={editData.SalePrice} onChange={(e) => handleEditChange(e)} />
            </Form.Group>
          </Row>

          <Row className="mandli-row mb-3 gap-3">
            <Form.Group as={Col} controlId="form-control-product">
              <Form.Label>Ticket Description</Form.Label>
              <Form.Control as="textarea" placeholder='Ticket Description' rows={3} value={editData.Description} name='TicketDescription' onChange={(e) => handleEditChange(e, "desc")} />
            </Form.Group>
          </Row>
          <Row className="mandli-row mb-3 gap-3">
            <Form.Group as={Col} controlId="form-control-product">
              <Form.Label>Start Sale Date</Form.Label>
              <Form.Control type="datetime-local" placeholder="Start Sale Date" value={editData.StartsaleFormated} name="Startsale" onChange={(e) => handleEditChange(e, "dateS")} />
            </Form.Group>

            <Form.Group as={Col} controlId="form-control-product">
              <Form.Label>End Sale Date</Form.Label>
              <Form.Control type="datetime-local" placeholder="End Sale Date" value={editData.EndsaleFormated} name="Endsale" onChange={(e) => handleEditChange(e, "dateE")} />
            </Form.Group>
          </Row>
          <Row className="mandli-row mb-3 gap-3">
            <Form.Group as={Col} controlId="form-control-product">
              <Form.Label>Capacity</Form.Label>
              <Form.Control type="number" placeholder="Capacity" value={editData.Capacity} name="Capacity" onChange={(e) => handleEditChange(e)} />
            </Form.Group>

            <Form.Group as={Col} controlId="form-control-product">
              <Form.Label>SKU</Form.Label>
              <Form.Control type="text" placeholder="SKU" value={editData.SKU} name="SKU" onChange={(e) => handleEditChange(e)} />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat className='modelClose' onClick={(e) => closeEdit(e)}>
            Cancel
          </Button>
          <Button auto className='modelSubmit common-btn' onClick={(e) => handleEditTicket(e)}>
            Save Ticket
          </Button>
        </Modal.Footer>
      </Modal >

      {/* Sold Out Popup */}
      <Modal scroll width="600px" closeButton aria-labelledby="modal-title" aria-describedby="modal-description" {...ticketSoldOutPopupBindings} >
        <Modal.Header justify='start'>
          <Text id="modal-title" size={18}>
            Are You <Text b id="modal-title" size={18}> Sure!</Text>
          </Text>
        </Modal.Header>
        <Modal.Body className='deleteModel'>
          <Row className="mb-3">
            {
              soldOutTicketData?.flag === 1 ?
                <h2>You want to mark <br /><strong style={{ color: "var(--peimary)" }}>{soldOutTicketData?.TicketTitle}</strong> Ticket as <strong style={{ color: "var(--peimary)" }}>Sold Out</strong>?</h2> :
                soldOutTicketData?.flag === 4 ?
                  <h2>You want to mark <br /><strong style={{ color: "var(--peimary)" }}>{soldOutTicketData?.TicketTitle}</strong> Ticket as <strong style={{ color: "var(--peimary)" }}>Available</strong>?</h2> : ""
            }
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat className='modelClose' onClick={() => setTicketSoldOutPopupVisible(false)}>
            Cancel
          </Button>
          <Button auto className='modelSubmit common-btn' onClick={(e) => handleSoldOut(e, soldOutTicketData.flag, soldOutTicketData._id)}>
            Yes, {soldOutTicketData?.flag === 1 ? "Mark as Sold Out" : "Mark as Available"}
          </Button>
        </Modal.Footer>
      </Modal >

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
export default Tickets