import React from 'react'
import "./SiteFooter.css"
import { AiFillInstagram, AiFillYoutube } from 'react-icons/ai'
import { BsTicketPerforated } from 'react-icons/bs'
import Logo from '../../Images/home/nagri-logo.png'
import AT from '../../Images/AT.png'
import { NavLink } from 'react-router-dom'
import { FiInstagram, FiMail, FiPhone } from 'react-icons/fi'
import { FaFacebookF } from "react-icons/fa";


function SiteFooter() {

  const currentYear = new Date().getFullYear();

  return (

    <>

      <div className='Footer desktopOnlyFooter'>
        <div className="container">
          <div className="footer-top">
            <div className='left'>
              <img src={Logo} alt="Nagri Logo" />
            </div>
            <div className='right'>
              <div className='contact'>
                <NavLink to="mailto:info@nagri.co">
                  <FiMail style={{ color: "var(--primary)" }} />
                  <div className='icon_box_bt'>
                    info@nagri.co
                  </div>
                </NavLink>
                <div className='oneline '>
                  <FiPhone style={{ color: "var(--primary)" }} />
                  <NavLink to="tel:+917874834835">
                    <div className='icon_box_bt' style={{ fontSize: "15px" }}>
                      +91 78748 34835
                    </div>
                  </NavLink>
                  /
                  <NavLink to="tel:+917016794255">
                    <div className='icon_box_bt' style={{ fontSize: "15px" }}>
                      +91 70167 94255
                    </div>
                  </NavLink>
                </div>
                <NavLink to="https://www.instagram.com/nagri.na.norta/" target='_blank'>
                  <FiInstagram style={{ color: "var(--primary)" }} />
                </NavLink>
                <NavLink to="https://m.facebook.com/61565648877201" target='_blank'>
                  <FaFacebookF style={{ color: "var(--primary)" }} />
                </NavLink>
              </div>
            </div>
          </div>

          <div className="footerMain">
            <div className='row'>
              <div className="title">
                <div className='footerBuyNow'>
                  <h2><span>NAGRI NA NORTA!</span><br /> Join Legacy</h2>
                  <NavLink className="common-btn cst-font-size" to="/buy-now" ><BsTicketPerforated /><span>Book Passes</span></NavLink>
                </div>
              </div>
              <div className="links">
                <div className="title">
                  <h3>Useful Links</h3>
                  <ul>
                    <li><NavLink to="/about">About</NavLink></li>
                    <li><NavLink to="/gallery">Gallery</NavLink></li>
                    <li><NavLink to="/support">Support</NavLink></li>
                  </ul>
                </div>
              </div>
              <div className="links">
                <div className="title">
                  <h3>Other Links</h3>
                  <ul>
                    <li hidden><NavLink to="/support">Contact</NavLink></li>
                    <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
                    <li><NavLink to="/terms-and-conditions">Terms of Service</NavLink></li>
                    <li><NavLink to="/refund-and-cancellation">Refund & Cancellation</NavLink></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='Site_footer'>
          <div className='container'>
            <div className='coppy_rights'>
              <h1>&copy; {currentYear} Nagri</h1>
            </div>
            <div className="design_devlop_At">
              <NavLink to="https://ahuratechnosoft.com" >
                <h1>Designed and Developed by</h1>
                <img src={AT} alt='Designed and Developed by AT' />
              </NavLink>
            </div>
          </div>
        </div>
      </div>


      <div className='Footer mobileOnlyFooter'>
        <div className='container'>
          <div id="ft_blocks">
            <div className='title_ft'>
              <h2><span>NAGRI NA NORTA!</span><br /> Join Legacy</h2>
              <ul>
                <li><a href="https://www.instagram.com/nagri.na.norta/" target='_blank'><AiFillInstagram /></a></li>
                <li><a href="https://m.facebook.com/61565648877201" target='_blank'><FaFacebookF /></a></li>
              </ul>
            </div>
            <div className='icon-box'>
              <div className='ft_titile title_socials'>
                <p>Support</p>
              </div>
              <div className='oneline'>
                <FiPhone style={{fontSize: "16px", color:"var(--primary)"}} />
                <NavLink to="tel:+917874834835">
                  <div className='icon_box_bt'>
                    +91 78748 34835
                  </div>
                </NavLink>/
                <NavLink to="tel:+917016794255">
                  <div className='icon_box_bt'>
                    +91 70167 94255
                  </div>
                </NavLink>
              </div>
              <NavLink to="mailto:info@nagri.co">
                <FiMail style={{fontSize: "16px", color:"var(--primary)"}} />
                <div className='icon_box_bt'>
                  info@nagri.co
                </div>
              </NavLink>
            </div>

            <div className='sptr_pages'>
              <div className='ft_titile title_socials'>
                <p>Other Links</p>
              </div>
              <NavLink to="/support">Support</NavLink>
              <NavLink to="/privacy-policy">Privacy Policy</NavLink>
              <NavLink to="/terms-and-conditions">Terms of Service</NavLink>
              <NavLink to="/refund-and-cancellation">Refund & Cancellation</NavLink>
            </div>

            <div className='sptr_pages mobile'>
              <div className='spacer'>
                <NavLink to="/support">Support</NavLink>
                <NavLink to="/privacy-policy">Privacy Policy</NavLink>
              </div>
              <div className='spacer two'>
                <NavLink to="/terms-and-conditions">Terms of Service</NavLink>
                <NavLink to="/refund-and-cancellation">Refund & Cancellation</NavLink>
              </div>
            </div>

          </div>
        </div>

        <div className='Site_footer'>
          <div className='container'>
            <div className='coppy_rights'>
              <h1>&copy; {currentYear} Nagri</h1>
            </div>
            <div className="design_devlop_At">
              <NavLink to="https://ahuratechnosoft.com" >
                <h1>Designed and Developed by</h1>
                <img src={AT} alt='Designed and Developed by AT' />
              </NavLink>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default SiteFooter