import React, { useEffect, useState } from 'react';
import './Gallery.css'
import 'react-image-lightbox/style.css'; // Import the styles
import Formats from '../../Components/Formats/Formats';

function Gallery() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section className="Privacy-Policys buyNow">
            <div className='inner-banner'>
                <div className="container">
                    <h1>Gallery</h1>
                </div>
            </div>

            <div className="main About">
                <div className="container">
                    <div className="gallery_box">
                        <Formats />
                    </div>
                </div>
            </div>

        </section>
    );
}

export default Gallery;
