import React, { useEffect } from 'react'
import './PrivacyPolicy.css'
import { NavLink } from 'react-router-dom'

function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <section className='Privacy-Policys buyNow'>
                <div className='inner-banner'>
                    <div className="container">
                        <h1>Privacy Policy</h1>
                    </div>
                </div>
            </section>

            <div className='PrivacyPolicy'>
                <section className='section PrivacyPolicy' id='privacy'>
                    <div className='container'>
                        <div className='formats-grid'>
                            <div className='inner-box'>
                                <div className='box-content'>
                                    <h2>INTRODUCTION</h2>
                                    <p>This Privacy Policy applies to our website [https://nagri.co](https://nagri.co). By accessing or using the website, you confirm that you have read, understood, and agreed to the collection, storage, use, and disclosure of your personal information as described in this Privacy Policy.</p>
                                    <h2>APPLICABILITY</h2>
                                    <p>This Privacy Policy applies exclusively to the <b>Nagri</b> website and is effective immediately upon your visit. By using our website, you agree to be bound by this Privacy Policy. It applies in all instances when you access the site, including while registering, making transactions through third-party payment gateways, or simply browsing the site.</p>
                                    <h2>COOKIE POLICY</h2>
                                    <p>By visiting theNagri website, you consent to our use of cookies in accordance with our Cookie Policy. Cookies help us collect, store, and disclose certain information to improve your user experience. We use both session cookies (which expire when you close your browser) and persistent cookies (which remain until deleted or expired). These cookies help our website function properly and allow us to analyze usage data. Cookies created by us are referred to as "first-party cookies."</p>
                                    <h2>COLLECTION OF INFORMATION</h2>
                                    <p>We collect information from you that is necessary to provide our services:</p>
                                    <ul>
                                        <li><p>During registration, we collect personal data such as your name, email address, phone number, and postal address.</p></li>
                                        <li><p>We also collect and store usage data such as your IP address, browser type, pages viewed, and browsing activity for analysis, which helps us enhance your experience and offer value-added services.</p></li>
                                    </ul>
                                    <p>We retain the information provided by you as long as it is required for service delivery or any legal purposes.</p>
                                    <h2>USE OF INFORMATION</h2>
                                    <p>The information we collect may be used for the following purposes:</p>
                                    <ul>
                                        <li><p>To verify your identity and eligibility.</p></li>
                                        <li><p>To facilitate financial transactions, such as payments and transfers.</p></li>
                                        <li><p>To send communications, including notifications, promotions, and offers.</p></li>
                                        <li><p>To conduct research and analytics on user behavior and demographics.</p></li>
                                    </ul>
                                    <h2>DISCLOSURE OF INFORMATION</h2>
                                    <p>Information collected from you may be shared with external service providers who assist in delivering services. These may include:</p>
                                    <ul>
                                        <li><p>Aggregated demographic information shared with third parties, including advertisers, to attract relevant advertising on <b>Nagri</b>. We do not share personal data with advertisers.</p></li>
                                        <li><p>Government or regulatory bodies, when required by law for identity verification or crime prevention.</p></li>
                                        <li><p>Service providers (including payment and cloud computing services) that support our service delivery.</p></li>
                                    </ul>
                                    <p>We take reasonable measures to ensure that any third-party service provider maintains the confidentiality and security of your personal data.</p>
                                    <h2>NETWORK OPERATORS</h2>
                                    <p>The use ofNagri's services may involve third-party telecommunications providers. These providers are not our contractors, and any information collected by them during the use of <b>Nagri</b>'s services is not considered "Personal Information" under this Privacy Policy.Nagri is not responsible for the actions or omissions of telecommunications carriers.</p>
                                    <h2>PROTECTION OF INFORMATION</h2>
                                    <p>We use industry-standard security measures to protect your data from unauthorized access and ensure its accuracy. However, no data transmission over the Internet can be guaranteed to be completely secure. We recommend not sharing your passwords or sensitive information with anyone, including <b>Nagri</b> staff.</p>
                                    <p>To protect your account, avoid sharing your website login credentials and make sure to sign out after using the site, especially on shared or public computers.</p>
                                    <h2>APPLICABLE LAW AND JURISDICTION</h2>
                                    <p>By using this website, you agree that your relationship withNagri and the terms of this Privacy Policy are governed by the laws of India. Any disputes arising in connection with this Privacy Policy or your use of the <b>Nagri</b> website will be subject to the jurisdiction of the courts located in Gujarat, India.</p>
                                    <p>Please also review the Terms and Conditions available on the website. Your use of the website signifies that you have read and agreed to both the Terms and Conditions and this Privacy Policy.</p>
                                    <h2>MISCELLANEOUS</h2>
                                    <p>In case of any conflict regarding the terms of this Privacy Policy, the decision ofNagri will be final. By using this website, you waive the right to challenge the terms of this Privacy Policy.</p>
                                    <p>Please make sure to review both the Terms and Conditions and this Privacy Policy regularly, as they govern your relationship with <b>Nagri</b>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default PrivacyPolicy