import React from 'react'
import LogoImg from '../../Images/home/nagri-logo.png'
import { NavLink, Navigate } from 'react-router-dom'
import AT from '../../Images/AT.png'
import PaymentFail from '../../Images/Mandli/pf2.png';

function PaymentFailed() {

    const currentYear = new Date().getFullYear();


    return (
        <section className='ThankYou'>

            <div className='main_body'>
                <div className='container'>
                    <div className='thank_you_container'>

                        <div className='userbox-lg-search logo'>
                            <NavLink to="/" onClick={() => Navigate('/')}><img src={LogoImg} width={"200"} /></NavLink>
                        </div>

                        <div className='Block_details_order'>
                            <div className='tnk_title'>
                                <img src={PaymentFail} alt="" />
                                <h3 className="animate-charcter payment_failed">Oops, Payment Failed</h3>
                            </div>
                            <div className='order_details_before_coming payment_failed'>
                                <p>Please try other payment mode.</p>
                            </div>
                            <div className="view_order_btn">
                                <NavLink to="/checkout" className="usdercmntbtn common-btn" >
                                    <span>Re-Try</span>
                                </NavLink>
                            </div>
                        </div>

                        <div className='thank_you_footer payment_failed'>
                            <div className='Site_footer'>
                                <div className='container'>
                                    <div className='coppy_rights'>
                                        <h1>&copy; {currentYear} Mandli</h1>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </section>
    )
}

export default PaymentFailed