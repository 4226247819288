import React, { useEffect, useState } from 'react'
import './Orders.css'
import { calculatePriceBreakdown, getToken } from '../../Helper/Token';
import axios from 'axios';
import { getPatchUserDetails, getUserOrder, getUserTicketOrder } from '../../Api';
import { Pagination } from '@nextui-org/react';
import garbaImg from '../../Images/Mandli/about.png'
import { NavLink, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Edit } from '@mui/icons-material';
import { BsChevronDown } from 'react-icons/bs';

function Orders() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // Get User Details
  const [userDetails, setUserDetails] = useState({});
  const fetchUserDetails = () => {
    getToken();
    axios.patch(getPatchUserDetails)
      .then((res) => {
        setUserDetails(res.data.user);
      });
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);


  // Pagination
  const [pageState, setPageState] = useState({
    total: 0,
    totalPages: 0,
    page: 1,
    pageSize: 3
  })
  const handlePageChange = (selectedPage) => {
    setPageState((prevState) => ({ ...prevState, page: selectedPage }));
  };
  const ResultInfo = () => {
    const start = (pageState.page - 1) * pageState.pageSize + 1;
    const end = Math.min(start + pageState.pageSize - 1, pageState.total);

    return (
      <div className="result-info">
        Showing <span>{start}-{end}</span> of <span>{pageState.total}</span> Orders
      </div>
    );
  };


  // Get all Orders
  const [orders, setOrders] = useState([]);
  const fetchOrdersList = () => {
    getToken();
    if (userDetails._id) {
      axios.get(`${getUserTicketOrder}/${userDetails._id}?page=${pageState.page}&limit=${pageState.pageSize}&paymentStatus=1`)
        .then((res) => {
          res.data.result.docs.map((ele) => {
            const json = res.data.result;
            setPageState((prevState) => ({ ...prevState, total: json.totalDocs, totalPages: json.totalPages }));

            const dateObject = new Date(ele.createdAt);

            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

            const day = dateObject.getDate();
            const monthIndex = dateObject.getMonth();
            const year = dateObject.getFullYear();
            const hours = dateObject.getHours();
            const minutes = dateObject.getMinutes();
            const amPm = hours >= 12 ? 'PM' : 'AM';
            const hours12 = hours % 12 || 12; // Convert to 12-hour format

            ele.formattedDateTime = `${day} ${months[monthIndex]}, ${year} ${hours12}:${minutes < 10 ? '0' : ''}${minutes}${amPm}`;
          })
          setOrders(res.data.result.docs);
        });
    }
  };

  useEffect(() => {
    fetchOrdersList();
  }, [userDetails._id, pageState.page, pageState.pageSize]);

  // Display Orders Toggle
  const [isVisible, setIsVisible] = useState(true)
  const handleToggle = () => {
    if (isVisible) {
      setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }

  return (

    <>
      <div className="mobileTab other" onClick={() => navigate('/profile')}>
        <div className="left-text">
          <h4>Hi, {userDetails.name}</h4>
          <NavLink className="EditBtn" to="/profile/setting" key="Setting" id="Setting"  >
            <div className="ussericon"><Edit /></div>
            <div className="user-link_text">Edit profile</div>
          </NavLink>
        </div>
        <div>
          <BsChevronDown />
        </div>
      </div>
      <div className='tabMenu orders'>
        <div className="mobileTab" onClick={() => handleToggle()}>
          <div className="left-text">
            <h4>Orders</h4>
            <p>View your orders details</p>
          </div>
          <div className={isVisible ? "open" : ""}>
            <BsChevronDown />
          </div>
        </div>
        <section className='dashboard opage'>
          <div className="dashboard-right-header fr05s-usr-quotsn05s">
            <div className="left-text">
              <h4>Orders</h4>
              <p className="m-0">View your order details</p>
            </div>
          </div>
          <div className={`orderList ${isVisible ? "show" : ""}`}>
            {
              orders.length > 0 ?
                orders.map((order) => {
                  return (<div className='order'>
                    <div className="order-header">
                      <div className='orderDetails'>
                        <h3>Order: <span>#{order.OrderId}</span></h3>
                        <p>{order.formattedDateTime}</p>
                      </div>
                      <div className='viewDetails'>
                        <NavLink to={`/orders/vieworder/${order._id}`} className="common-btn w-auto">View Tickets</NavLink>
                      </div>
                    </div>
                    <div className='order-body'>
                      <div className="ticket-item">
                        <p>Ticket Name</p>
                        <p>Price/Qty</p>
                        <p>Amount</p>
                      </div>
                      {
                        order.TicketInfo?.map((ticket) => {
                          return (<div className="ticket-item">
                            <p>{ticket.TicketTitle}</p>
                            <p>{ticket.SalePrice && ticket.SalePrice > 0 ? <>&#8377;{ticket.SalePrice}</> : (<>&#8377;{ticket.Price}</>)} &#215; {ticket.PurchasedTickets}</p>
                            <p>&#8377;{`${ticket.SalePrice && ticket.SalePrice > 0 ? ticket.SalePrice : ticket.Price}` * ticket.PurchasedTickets}</p>
                          </div>)
                        })
                      }
                    </div>
                    <div className='total'>
                      {
                        order.PaymentStatus === 2 &&
                        <div className='subtotal'>
                          <p>Payment Status</p>
                          <p className='success'>{order.PaymentStatus === 2 && "Success"}</p>
                        </div>
                      }
                      {order?.DiscountInfo[0].discount > 0 &&
                        <>
                          <div className='subtotal'>
                            <p>Net Price</p>
                            <p>&#8377;{order?.DiscountInfo[0]?.discountAmount + order.OrderTotal}</p>
                          </div>
                          <div className='subtotal'>
                            <p>Discount ({order?.DiscountInfo[0]?.discount}%) <br />Coupon: {order?.DiscountInfo[0].couponCode}</p>
                            <p>&#8377;{order?.DiscountInfo[0]?.discountAmount}</p>
                          </div>
                        </>
                      }
                      <div className='subtotal'>
                        <p>Total item</p>
                        <p>{order.PurchasedTickets}</p>
                      </div>
                      <div className='grandtotal'>
                        <p>Grand Total</p>
                        <p>&#8377;{order.OrderTotal}</p>
                      </div>
                    </div>
                  </div>)
                })
                :
                <div className='orderNotFound'>
                  <div className='bookNow'>
                    <h1>No Orders Found</h1>
                    <p>Book your Tickets Now!</p>
                    <NavLink className="common-btn big cst-font-size" to="/buy-now" ><span>Book Passes</span></NavLink>
                  </div>
                  <img src={garbaImg} alt="" />
                </div>
            }
            {
              pageState.totalPages > 1 &&
              <div className='pagination-block'>
                <ResultInfo />
                <Pagination
                  loop
                  color="success"
                  total={pageState.totalPages}
                  initialPage={1}
                  onChange={(page) => handlePageChange(page)}
                  currentPage={pageState.page}
                />
              </div>
            }
          </div>
        </section >
      </div>

    </>
  )
}

export default Orders