import React from 'react'
import './Error.css'
import wheel from '../../Images/nagri/rotating-circle.svg'
import NagriLogo from '../../Images/home/nagri-logo.png'
import { NavLink, useNavigate } from 'react-router-dom'

function Error() {
    const navigate = useNavigate();
    return (
        <div className='comming-soon error'>
            <div className="mandli">
                <img src={NagriLogo} alt="Nagri Logo" />
                <p>404 Not Found</p>
                <NavLink className="button-86 cst-font-size" to="/buy-now" onClick={() => navigate(-1)} ><span>Go Back</span></NavLink>
            </div>
            <div className="wheel">
                <img src={wheel} alt="Animation" />
            </div>
        </div>
    )
}

export default Error