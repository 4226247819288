import React, { useEffect, useState } from 'react'
import './Support.css'
import { NavLink } from 'react-router-dom';
import { FormControlLabel, IconButton } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { getSupportList } from '../../Api';
import axios from 'axios'
import { getAdminToken } from '../../Helper/Token';
import CloseIcon from '@mui/icons-material/Close';
import { Col, Modal, Pagination, Row, Switch, Text, useModal } from '@nextui-org/react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import { SuperBalls } from '@uiball/loaders';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

function Support() {

  const [open, setOpen] = React.useState(false);
  const [currentIndex, setCurrentIndex] = useState(0)
  const handleOpen = (index) => {
    setProductEditModalVisible(true)
    setCurrentIndex(index)
  }
  const handleClose = () => setProductEditModalVisible(false);

  const { setVisible: setProductEditModalVisible, bindings: addTicketModalBindings } = useModal();


  const columns = [
    { field: 'id', headerName: 'Id', width: 40 },
    { field: 'Name', headerName: 'Name', width: 130, },
    { field: 'Email', headerName: 'Email', width: 130, },
    { field: 'Description', headerName: 'Description', width: 130, },
    { field: 'Issue', headerName: 'Subject', width: 200, },
    { field: 'formattedDateTime', headerName: 'Date', width: 130, },
    {
      field: "actions", headerName: "Action", sortable: false, width: 175,
      renderCell: (prevents) => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
            <MatView index={prevents.row} />
          </div>
        );
      }
    }
  ];


  const [pageState, setPageState] = useState({
    total: 0,
    totalPages: 0,
    page: 1,
    pageSize: 10
  })
  const handlePageChange = (selectedPage) => {

    setPageState((prevState) => ({ ...prevState, page: selectedPage }));
  };
  const ResultInfo = () => {
    const start = (pageState.page - 1) * pageState.pageSize + 1;

    const end = Math.min(start + pageState.pageSize - 1, pageState.total);
    return (
      <div className="result-info">
        Showing <span>{start}-{end}</span> of <span>{pageState.total}</span> Tickets
      </div>
    );
  };
  const handlePageSizeChange = (newPageSize) => {
    setPageState((prevState) => ({
      ...prevState,
      page: 1,
      pageSize: newPageSize,
    }));
  };

  // Get Admin List
  const [rows, setRows] = useState([]);
  const fetchSupportList = () => {
    getAdminToken();
    axios.get(`${getSupportList}?page=${pageState.page}&limit=${pageState.pageSize}`)
      .then((res) => {
        const json = res.data.result;
        setPageState((prevState) => ({ ...prevState, total: json.totalDocs, totalPages: json.totalPages }));

        res.data.result.docs.map((ele, i) => {
          const dateObject = new Date(ele.createdAt);
          const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
          const day = dateObject.getDate();
          const monthIndex = dateObject.getMonth();
          const year = dateObject.getFullYear();
          const hours = dateObject.getHours();
          const minutes = dateObject.getMinutes();
          const amPm = hours >= 12 ? 'PM' : 'AM';
          const hours12 = hours % 12 || 12; // Convert to 12-hour format

          ele.id = i + 1;
          ele.formattedDateTime = `${day} ${months[monthIndex]}, ${year} ${hours12}:${minutes < 10 ? '0' : ''}${minutes}${amPm}`;
        })
        setRows(res.data.result.docs)
      })
  }
  useEffect(() => {
    fetchSupportList();
  }, [pageState.page, pageState.pageSize])

  // Open Image On View
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage('');
    setIsModalOpen(false);
  };



  const MatView = ({ index }) => {
    return (
      <FormControlLabel
        control={
          <>
            <NavLink className="view_user_order" key={index.id}>
              <IconButton aria-label="add an alarm" className='dyFlextIcon'>
                <Button onClick={() => handleOpen(index)}><VisibilityOutlinedIcon /></Button>
              </IconButton>
            </NavLink>
          </>
        }
      />
    );
  };

  return (
    <section className='adminDashboard support_lists'>
      <div className="dashboard-right-header fr05s-usr-quotsn05s">
        <div className="left-text">
          <h4>Support</h4>
          {/* <p className="m-0">Here is all Support List</p> */}
        </div>
      </div>

      {isModalOpen && (
        rows.map((row) => (
          <div className={`image-modal ${isModalOpen ? 'visible' : ''}`} key={row._id}>
            <div className="modal-content">
              <span className="close" onClick={closeModal}><CloseIcon /></span>
              <img src={`http://localhost:3001/${row.Attachment}`} alt="Image" />
            </div>
          </div>
        ))
      )}

      <div className='datagridtableusqot00qat'>
        <Box sx={{ width: '100%' }}>
          <DataGrid
            autoHeight {...rows}
            rows={rows}
            columns={columns}
            pageSize={pageState.pageSize}
            disableSelectionOnClick
            pagination={false}
            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  <SuperBalls size={45} speed={1.4} color="var(--primary)" />
                </Stack>
              )
            }}
          />
        </Box>
      </div>
      <div className='pagination-block'>
        <ResultInfo />
        <div className='customPagination'>
          <div className="page-size-select">
            Page Size:{" "}
            <select
              value={pageState.pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <Pagination
            loop
            color="success"
            total={pageState.totalPages}
            initialPage={1}
            boundaries={1}
            siblings={0}
            onChange={(page) => handlePageChange(page)}
            currentPage={pageState.page}
          />
        </div>
      </div>

      <Modal scroll width="700px" closeButton aria-labelledby="modal-title" aria-describedby="modal-description" {...addTicketModalBindings} >
        <Modal.Header justify='start'>
          <Text id="modal-title" size={18}>
            <b>{currentIndex.Name}</b> need support
          </Text>
        </Modal.Header>
        <Modal.Body>
          <div className="header fr05s-usr-quotsn05s" key={currentIndex._id}>
            <div className="left-text support_Model">
              <p><b>Name : </b>{currentIndex.Name}</p>
              <p><b>Email : </b>{currentIndex.Email}</p>
              <p><b>Phone : </b>{currentIndex.Phone}</p>
              <p><b>Subject : </b>{currentIndex.Issue}</p>
              <p><b>Description : </b>{currentIndex.Description}</p>
            </div>
          </div>
          <div className="image-modal" key={rows?._id}>
            {
              currentIndex.Attachment ?
                <div className="modal-content-img">
                  <img src={`${currentIndex.Attachment}`} alt="Image" />
                </div> : ""
            }
          </div>
        </Modal.Body>
      </Modal>
    </section>
  )
}

export default Support