import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

function TermsandConditions() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <>
            <section className='Privacy-Policys buyNow'>
                <div className='inner-banner'>
                    <div className="container">
                        <h1>Terms & Conditions</h1>
                    </div>
                </div>
            </section>

            <div className='PrivacyPolicy'>
                <section className='section PrivacyPolicy' id='privacy'>
                    <div className='container'>
                        <div className='formats-grid'>
                            <div className='inner-box'>
                                <div className='box-content'>
                                    <p>This Agreement outlines the terms and conditions (the "Agreement") that apply to users accessing or using the application and website <a href='https://nagri.co'>(https://nagri.co)</a>, developed by <b>Nagri</b> to provide technical support for organizing events.</p>
                                    <p><b>Nagri</b> reserves the right to modify this Agreement at any time. Any changes will become effective once posted on the website. By continuing to use the Nagri platform, you agree to adhere to any updates or modifications. Users are encouraged to periodically review this Agreement to stay informed about any changes.</p>
                                    <h2>DEFINITIONS</h2>
                                    <ul>
                                        <li><p>Account: Refers to a unique account created by users to access Nagri's services for event planning and management.</p></li>
                                        <li><p>Company: Refers to <b>Nagri</b>, the entity responsible for providing and operating the services mentioned in this Agreement (referred to as "we," "our," or "us").</p></li>
                                        <li><p>Personal Data: Any information that can identify an individual, such as name, phone number, and address, provided by the user.</p></li>
                                        <li><p>You: Any person or entity registered with <b>Nagri</b> for the use of the services provided (referred to as "user" or "consumer").</p></li>
                                        <li><p>Third-party Service: Refers to external partners such as advertisers, payment gateways, or service providers that may collaborate with Nagri.</p></li>
                                    </ul>
                                    <h2>1. SERVICES OFFERED</h2>
                                    <p>Nagri provides an online platform for users to book tickets and passes for various events it organizes, such as Garba festivals, concerts, and other social gatherings. The platform offers users updates on upcoming events, promotions, and contests to win event passes.</p>
                                    <h2>2. LICENSE</h2>
                                    <p>Subject to compliance with this Agreement, <b>Nagri</b> grants you a limited, non-exclusive, revocable, non-transferable license to access and use its services through your user account. This license is provided for your personal use in connection with Nagri's events and activities.</p>
                                    <h2>3. PRIVACY POLICY</h2>
                                    <p>Nagri is committed to protecting the privacy of users and processing personal data in accordance with the Information Technology Act, 2000. By accessing or using our services, users agree to the collection and processing of their personal information as outlined in our Privacy Policy, available on (https://nagri.co).</p>
                                    <h2>4. USER ACCOUNTS</h2>
                                    <p>To access Nagri's services, users must register an account by providing accurate and up-to-date information. Users are responsible for safeguarding their account credentials and for any activities conducted through their account. Nagri advises users not to share their passwords and to sign out after completing their work, especially when using shared or public computers.</p>
                                    <p>Users must notify Nagri immediately in case of any unauthorized use of their account.</p>
                                    <h2>5. PAYMENT METHODS</h2>
                                    <p>Payments for events and services on the Nagri platform can be made using various payment options, including debit cards, credit cards, net banking, UPI, and e-wallets. Payments must be made in advance, and Nagri reserves the right to change its pricing or payment methods at any time by posting updates on the website or notifying users via email.</p>
                                    <h2>6. RESTRICTIONS</h2>
                                    <p>Users are prohibited from:</p>
                                    <ul>
                                        <li><p>Selling, copying, sublicensing, or transferring any information, intellectual property, or services provided by Nagri.</p></li>
                                        <li><p>Using the platform for illegal purposes.</p></li>
                                        <li><p>Modifying other websites to misrepresent their association with Nagri.</p></li>
                                        <li><p>Accessing the services to build a similar or competitive platform.</p></li>
                                    </ul>
                                    <p>Violation of these restrictions may result in the suspension or termination of your access to the platform.</p>
                                    <h2>7. TERMINATION OF SERVICES</h2>
                                    <p>Nagri reserves the right to modify, suspend, or discontinue its services at any time without notice. This includes terminating user accounts for any violations of this Agreement or for providing false information. Nagri is not responsible for any loss or damage resulting from the modification or discontinuation of its services.</p>
                                    <h2>8. INTELLECTUAL PROPERTY</h2>
                                    <p>All content on the Nagri website, including text, images, software, and designs, is owned by Nagri and is protected by copyright and other intellectual property laws. Users are granted a limited license to access the content for personal use, but unauthorized copying, modification, or distribution is prohibited.</p>
                                    <h2>9. COPYRIGHT INFRINGEMENT</h2>
                                    <p>Nagri respects the intellectual property rights of others. If you believe your copyrighted work has been used without permission, please contact Nagri with the following information:</p>
                                    <ul>
                                        <li><p>Your name, address, and contact details.</p></li>
                                        <li><p>A description of the copyrighted work and its unauthorized use on the platform.</p></li>
                                        <li><p>A statement of good faith belief that the use is unauthorized.</p></li>
                                        <li><p>A declaration that the information provided is accurate and that you are authorized to act on behalf of the copyright owner.</p></li>
                                    </ul>
                                    <h2>10. DISCLAIMER OF WARRANTIES</h2>
                                    <p>Nagri provides its services on an "as-is" basis without warranties of any kind. While we strive to ensure the reliability of the platform, Nagri cannot guarantee uninterrupted service or error-free content. Users assume full responsibility for their use of the platform, and Nagri disclaims liability for any damages that may result from using its services.</p>
                                    <h2>11. LIMITATION OF LIABILITY</h2>
                                    <p>In no event shall Nagri or its affiliates be liable for any indirect, incidental, or consequential damages arising from your use of the platform. Nagri's liability will be limited to the amount paid by the user for the services during their term of use.</p>
                                    <h2>12. INDEMNIFICATION</h2>
                                    <p>Users agree to indemnify Nagri and its affiliates from any claims, damages, or liabilities arising from their use of the platform, violation of this Agreement, or infringement of any rights of a third party.</p>
                                    <h2>13. GOVERNING LAW</h2>
                                    <p>This Agreement is governed by the laws of India. Any disputes arising from the use of the Nagri platform will be subject to the jurisdiction of courts located in Ahmedabad, Gujarat.</p>
                                    <h2>14. MISCELLANEOUS</h2>
                                    <p>If any provision of this Agreement is deemed invalid or unenforceable by a court, the remaining provisions will remain in effect. Users agree that Nagri may assign its rights and obligations under this Agreement without notice.</p>
                                    <p>For further inquiries or to report any violations of this Agreement, users may contact Nagri at: <a href='mailto:info@ganri.co'>Info@Nagri.co</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}

export default TermsandConditions