import React, { useEffect, useState } from 'react'
import "./AdminHeader.css"
import { Dropdown, Avatar, Text, Grid, Modal, Button, useModal } from "@nextui-org/react";
import { NavLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { getAdminToken, getToken } from '../../../Helper/Token';
import LogoImg from '../../../Images/home/nagri-logo.png'
import { getAdminDetails } from '../../../Api';
import UserIcon from '../../../Images/home/user.png'
import AdminSidebar from '../DefultSidebar/AdminSidebar';


function AdminHeader() {
  const navigate = useNavigate();

  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  
  const closeAdminMobileMenu = () => {
    setMenuOpen(!menuOpen);
  }

  // Get Admin Details
  const [adminDetails, setAdminDetails] = useState({});
  const fetchAdminDetails = () => {
    getAdminToken();
    axios.patch(getAdminDetails)
      .then((res) => {
        setAdminDetails(res.data.admin)
      })
  }
  useEffect(() => {
    fetchAdminDetails();
  }, [])


  return (
    <div className='UserHeader'>
      <div className='Usernavigationmenu main-header'>
        <div className="container">
          <div className='userhdmsc01'>
            <div className='userbox-lg-search logo'>
              <NavLink to="/" onClick={() => navigate('/')}> <div id="header">
                <div id="nav">
                  <div className='userbox-lg-search logo'>
                    <NavLink to="/admin/dashboard" onClick={() => navigate('/')}><img src={LogoImg} width={"200"} /></NavLink>
                  </div>
                </div>
              </div></NavLink>
            </div>
            <div className='userheadernssvblocksc02'>
              <div className='nav-menu'>
                <ul className='usernavmenulisted'>
                  <li className='button desktopOnly'>
                    <NavLink to='/admin/dashboard'><img src={UserIcon} width="30" height="30" alt="" /> Hello, {adminDetails?.name}</NavLink>
                  </li>
                  <li className='admin-menu'>
                    <button className={menuOpen ? "menu-toggler active" : "menu-toggler"} id='menu-close' type="button" onClick={toggleMenu}>
                      <span className="line"></span>
                      <span className="line"></span>
                      <span className="line"></span>
                    </button>
                  </li>
                </ul>
              </div>

              <div className={menuOpen ? "das-header mobile-nav-menu admin show" : "das-header mobile-nav-menu admin"} id="nav-menu">
                <AdminSidebar closeMenu={closeAdminMobileMenu} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminHeader