import React, { useEffect, useState } from 'react'
import './ViewOrder.css'
import img from '../../Images/code.png'
import DownloadIcon from '@mui/icons-material/Download';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { getToken } from '../../Helper/Token';
import axios from 'axios';
import { getUserTicketsOrderDetails } from '../../Api';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { IconButton } from '@mui/material';

function ViewOrder() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const orderId = useParams()
  const navigate = useNavigate()

  // Get Order Details
  const [orderDetails, setOrderDetails] = useState({})
  const fetchOrderDetails = (e) => {
    getToken();
    axios.get(`${getUserTicketsOrderDetails}/${orderId.id}`)
      .then((res) => {
        res.data.isExist[0].PurchaseticketsData.map((ele) => {
          const dateObject = new Date(ele.createdAt);

          const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

          const day = dateObject.getDate();
          const monthIndex = dateObject.getMonth();
          const year = dateObject.getFullYear();
          const hours = dateObject.getHours();
          const minutes = dateObject.getMinutes();
          const amPm = hours >= 12 ? 'PM' : 'AM';
          const hours12 = hours % 12 || 12; // Convert to 12-hour format

          ele.formattedDateTime = `${day} ${months[monthIndex]}, ${year} ${hours12}:${minutes < 10 ? '0' : ''}${minutes}${amPm}`;

          const description = ele.TicketInfo.Description;
          const descriptionParts = description?.split(" ");
          let SpecificDays;
          const ComboDiscription = ele.TicketInfo.Description
          const IsCombo = ele.isCombo

          if (IsCombo) {
            const Combomatch = ComboDiscription.split(' ')
            const startDay = Combomatch[0].replace('th', '').replace('rd', '').replace('st', '').replace('nd', '');
            const endDay = Combomatch[2].replace('rd', '').replace('th', '').replace('st', '').replace('nd', '');
            const extractedRange = `${startDay}-${endDay}`;

            ele.SpecificDays = extractedRange;
          }
          else {
            if (descriptionParts && descriptionParts.length >= 1) {
              const dayPart = descriptionParts;
              const day = parseInt(dayPart); // Convert the day part to an integer
              if (!isNaN(day)) {
                ele.SpecificDays = day;
              }
            }
          }
        })
        setOrderDetails(res.data.isExist[0])
      })
  }
  useEffect(() => {
    if (orderId?.id) {
      fetchOrderDetails()
    }
  }, [orderId])

  // Download PDF
  const handleDownloadClick = (row) => {
    const link = document.createElement('a');
    link.href = `https://${row.PdfUrl}`;
    link.download = `https://${row.PdfUrl}`;
    link.setAttribute('target', "_blank")
    link.setAttribute('download', 'Mandli.pdf')
    link.setAttribute('rel', "noreferrer")
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <section className='cont'>
        <div className="dashboard dashboard-right-header userOrders">
          <div className='Go_back_Btn'>
            <NavLink onClick={() => navigate(-1)}><KeyboardBackspaceIcon /></NavLink>
          </div>
          <div className="left-text">
            <h4>Order #{orderDetails.OrderId}</h4>
            <p className='m-0'>Here are all tickets you have purchase in order #{orderDetails.OrderId}</p>
          </div>
        </div>

        <div className='tickets-container admin_tickets-container'>
          {
            orderDetails?.PurchaseticketsData?.map((ticket) => {
              return (
                <div className="ticket">
                  <div className="main">
                    <div className="name">
                      <h1>
                        <span>{ticket?.TicketInfo?.TicketTitle}</span>
                      </h1>
                    </div>
                    <div className={`date ${ticket?.isCombo ? "combo" : ""}`}>
                      <h1>{ticket.SpecificDays}<span>{ticket.isCombo ? "03th to 11th Oct" : ticket.TicketInfo?.Description}</span></h1>
                    </div>
                    <div className='where_and_when'>
                      <div className="qrcode">
                        <div className="ticketDevider"></div>
                        <div className="qrCode">
                          <img src={`https://${ticket.qrCode}`} alt="Mandli Tickets QR Code" />
                          <p>{ticket.TicketNo}</p>
                        </div>
                      </div>
                      <div className="where_when">
                        <div className="place">
                          <div className="venue">
                            <h1><span>Visitors :</span> {ticket.TicketInfo.PurchasedTickets}</h1>
                          </div>
                          <div className="venue">
                            <h1>Time :</h1>
                            <p>08:00 PM onwards</p>
                          </div>
                          <div className="venue">
                            <h1>Venue :</h1>
                            <NavLink to="https://maps.app.goo.gl/15q2u8d7xjE7Yf1eA" target='_blank'>
                              Nagri Na Norta, Karnavati University, <br />
                              Uvarsad-Adalaj Road, Uvarsad, <br />
                              Gandhinagar, Gujarat 382422
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='download'>
                      <IconButton aria-label="Download PDF" className='dyFlextIcon' target='_blank' onClick={() => handleDownloadClick(ticket)} download>
                        <DownloadIcon />
                      </IconButton>
                    </div>
                  </div>

                </div>
              )
            })
          }
        </div>
        <div className='terms'>
          <h3>Terms & Conditions</h3>
          <ul class='m-0'>
            <li>Ticket Purchase:By purchasing a ticket for the Nagri Na Norta 2024, you agree to the following
              terms and conditions.</li>
          </ul>
          <h3>Refunds</h3>
          <ul class='m-0'>
            <li>Tickets are non-transferable and non-refundable, except as required by law or in case of event
              cancellation.</li>
          </ul>

          <h3>Entry and Conduct</h3>
          <ul>
            <li>We reserve the right to refuse entry or remove attendees for disruptive behavior or
              non-compliance with event staff instructions.</li>
          </ul>

          <h3>Security</h3>
          <ul>
            <li>All attendees are subject to security checks.</li>
            <li>Prohibited items will not be allowed according to security protocols.</li>
            <li>Event is subject to government guidelines and permission.</li>
          </ul>

          <h3>Event Changes</h3>
          <ul>
            <li>Event details may change without prior notice.</li>
          </ul>

          <h3>Ticket Authentication</h3>
          <ul>
            <li>Only valid tickets will be accepted for entry.</li>
          </ul>

          <h3>Attire</h3>
          <ul>
            <li>Traditional attire is mandatory.</li>
          </ul>

          <h3>Re-entry</h3>
          <ul>
            <li>Re-entry is not allowed once you leave the venue.</li>
          </ul>

          <h3>Others</h3>
          <ul>
            <li>Entry opens at 09:00 pm.</li>
            <li>Ticket needs to be purchased above 8 years & above.</li>
            <li>By purchasing a ticket and attending the Nagri Na Norta 2024 you acknowledge that you have
              read,understood, and agreed to these terms and conditions.</li>
            <li>Failure to comply with these terms may result in denial of entry or removal from the event
              without a refund.</li>
            <li>These terms and conditions are subject to change from time to time at the discretion of the
              organizer.</li>
            <li><strong>Any decisions made by the organizer shall be deemed final.</strong></li>
          </ul>
        </div>
      </section>
    </>
  )
}

export default ViewOrder