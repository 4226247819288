import React, { useEffect, useState } from 'react'
import './Checkout.css'
import { NavLink, useNavigate } from 'react-router-dom'
import { calculatePriceBreakdown, getIsLogin, getToken } from '../../Helper/Token';
import { createTicketOrder, getAuthOtp, getPatchUserDetails, getValidateEmailPhoneMisMatch, getVerifyauthOtp, getVerifyCouponCode, razorAPI } from '../../Api';
import axios from 'axios';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { validateRequiredFields } from '../../Helper/validation'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsTicketPerforated } from 'react-icons/bs';
import { Modal, Text, useModal } from '@nextui-org/react';
import OTPInput from 'react-otp-input';
import Login from './Login';
import NagriLogo from '../../Images/home/nagri-logo.png'
import { AiOutlineCloseCircle } from 'react-icons/ai';
// import crypto from 'crypto';

const toastStyle = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
}

function Checkout() {

    useEffect(() => {
        // Check if the window width is greater than 767px (i.e., not mobile)
        if (window.innerWidth > 767) {
            const scrollTimeout = setTimeout(() => {
                if (document.documentElement.scrollHeight > 767) {
                    window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
                }
            }, 1000);

            // Clear the timeout if the component unmounts
            return () => clearTimeout(scrollTimeout);
        }
    }, []);


    // OTP
    const [isOtpSend, setIsOtpSend] = useState(false)
    const [otpLess, setOtpLess] = useState(false)
    const [showOtpLess, setShowOtpLess] = useState(false)
    const [isVerified, setIsVerified] = useState(false)
    const [showVarified, setShowVarified] = useState(false)

    // Timer
    const [timer, setTimer] = useState(30);

    useEffect(() => {
        let intervalId;
        if (timer > 0) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [timer]);

    // Send Otp
    const [phoneError, setPhoneError] = useState('')
    const [phoneErrorShow, setPhoneErrorShow] = useState(false)
    const handleSendOtp = (e, data) => {
        setErrShow(true)
        setPhoneErrorShow(true)
        if (phoneError === "") {
            axios.post(`${getValidateEmailPhoneMisMatch}?email=${ticketData.email}&phone=${ticketData.phone}`)
                .then((res) => {
                    const isEmailExist = res.data.emailExist;
                    const isphoneExist = res.data.phoneExist;

                    if (isEmailExist === isphoneExist) {
                        if (!isVerified && !allErrors) {
                            axios.post(getAuthOtp, { email: ticketData.email })
                                .then((res) => {
                                    setIsOtpSend(true)
                                    setTimer(20);
                                })
                        }
                    } else if (isEmailExist !== isphoneExist) {
                        setProductDeleteModalVisible(true)
                    }
                })
        }
        if (data === "otpLess") {
            setTimeout(() => {
                setOtpLess(true)
                setShowOtpLess(true)
            })
        }
    }

    // Get Otp
    const [OTP, setOTP] = useState("");
    const handleOTPChange = (otp) => {
        setOTP(otp);

        if (otp.length === 4) {
            const formData = new FormData()
            formData.append('email', ticketData.email)
            formData.append('code', otp)
            axios.post(getVerifyauthOtp, formData)
                .then((res) => {
                    setShowVarified(true)
                    setOtpLess(true)
                    setIsVerified(true)
                }).catch((err) => {
                    setOtpLess(false)
                    setShowVarified(true)
                    setIsVerified(false)
                })
        } else {
            setIsVerified("")
        }
    };

    // MissMatch Popup
    const { setVisible: setProductDeleteModalVisible, bindings: productDeleteModalBindings } = useModal();
    const closeProductDeleteModel = () => {
        fetchUserDetails();
        setIsLogin(true)
        setProductDeleteModalVisible(false)
    }


    // Variables
    const navigate = useNavigate();
    let [isLogin, setIsLogin] = useState(false);
    const [price, setPrice] = useState({});
    const [ticketData, setTicketData] = useState({
        name: "",
        ticketId: [],
        email: "",
        phone: ""
    });
    const [userDetails, setUserDetails] = useState({});
    const [error, setError] = useState(false);

    // Get is Login
    useEffect(() => {
        setIsLogin(getIsLogin())
    }, [])

    const handleChecked = (e, data) => {
        if (data === "otpLess") {
            if (e.target.checked) {
                setOtpLess(true)
                setIsVerified(true)
                setIsOtpSend(false)
            } else {
                setOtpLess(false)
                setIsVerified(false)
                setIsOtpSend(true)
            }
        }
    }

    // Get selected tickets
    const [purchaseData, setPurchaseData] = useState({});
    useEffect(() => {
        const purchaseDataFromStorage = localStorage.getItem('purchaseTicketsData');
        if (purchaseDataFromStorage) {
            const parsedData = JSON.parse(purchaseDataFromStorage);
            if (parsedData?.purchaseTicketsData.length < 1) {
                toast.error("Your Cart is Empty!!", toastStyle);
                setTimeout(() => {
                    navigate('/buy-now');
                }, 2000);
            }
            setPurchaseData(parsedData);
            setPrice(calculatePriceBreakdown(parsedData.grandTotal, 18));
        } else {
            navigate('/buy-now');
        }
    }, []);

    // Get USer Details
    const fetchUserDetails = () => {
        getToken();
        axios.patch(getPatchUserDetails)
            .then((res) => {
                setUserDetails(res.data.user)
            });
    };
    useEffect(() => {
        if (isLogin) {
            fetchUserDetails()
        }
    }, [isLogin]);

    // Get Data
    const handleChange = (e) => {
        const { name, value } = e.target;
        setTicketData({
            ...ticketData,
            [name]: value,
        });
    };

    // Check Errors
    const [errorData, setErrorData] = useState({});
    const [errShow, setErrShow] = useState(false);
    useEffect(() => {
        const error = validateRequiredFields(ticketData, ["name", "ticketId", "email", "phone"]);
        setErrorData(error);
    }, [ticketData]);

    const [allErrors, setAllErrors] = useState(true);
    useEffect(() => {
        if (Object.keys(errorData).length === 0) {
            setAllErrors(false);
        } else if (Object.keys(errorData).length === 0 && isLogin) {
            setAllErrors(false);
        } else {
            setAllErrors(true);
        }
    }, [errorData]);

    useEffect(() => {
        if (isLogin) {
            setTicketData({
                ...ticketData,
                name: userDetails.name,
                ticketId: purchaseData.selectedTickets,
                email: userDetails.email,
                phone: userDetails.phone
            })
        } else {
            setTicketData({
                ...ticketData,
                ticketId: purchaseData.selectedTickets,
            })
        }
    }, [isLogin, userDetails, purchaseData])

    // Discount Coupon
    const [couponCode, setCouponCode] = useState("");
    const [couponCodeError, setCouponCodeError] = useState('');
    const [couponCodeErrorShow, setCouponCodeErrorShow] = useState('');

    const handleCouponCodeChange = (e) => {
        const { value } = e.target;
        const upperCaseValue = value.toUpperCase();
        setCouponCode(upperCaseValue);
    };

    useEffect(() => {
        if (!couponCode) {
            setCouponCodeError("Please enter Discount Code!");
        } else if (couponCode.length < 3) {
            setCouponCodeError("Discount Code must be at least 3 characters long!");
        } else {
            setCouponCodeError("");
        }
    }, [couponCode]);


    const [isValidCouponCode, setIsValidCouponCode] = useState(false);
    const [discountPercentage, setDiscountPercentage] = useState(0);
    const handleApplyCouponCode = (e) => {
        e.preventDefault();
        setCouponCodeErrorShow(true)

        // Calculat total visitor
        let ticketCount = 0;
        purchaseData.purchaseTicketsData.forEach((ele) => {
            if (ele.TicketTitle === "Season Pass") {
                ticketCount += ele.quantity * 9;
            } else {
                ticketCount += ele.quantity;
            }
        });

        const data = {
            couponCode: couponCode,
            purchaseTickets: Number(ticketCount)
        }

        if (couponCodeError === "") {
            axios.post(getVerifyCouponCode, data)
                .then((res) => {
                    if (res?.data?.result?.isActive) {
                        setCouponCodeErrorShow(false)
                        console.log(purchaseData);
                        setIsValidCouponCode(true)
                        setDiscountPercentage(res.data.result.discount);
                    } else {
                        setIsValidCouponCode(false)
                        setDiscountPercentage(0);
                    }
                })
                .catch((err) => {
                    if (err) {
                        console.log(err);
                        setCouponCodeError(err?.response?.data?.message)
                        setIsValidCouponCode(false)
                        setDiscountPercentage(0);
                    }
                })
        }
    };

    useEffect(() => {
        if (discountPercentage !== undefined || discountPercentage !== null) {
            const purchaseDataFromStorage = localStorage.getItem('purchaseTicketsData');

            if (purchaseDataFromStorage) {
                const parsedData = JSON.parse(purchaseDataFromStorage);

                if (!parsedData?.purchaseTicketsData?.length) {
                    toast.error("Your Cart is Empty!!", toastStyle);
                    setTimeout(() => {
                        navigate('/buy-now');
                    }, 2000);
                } else {
                    // Calculate total price with discount
                    setPrice(calculatePriceBreakdown(parsedData.grandTotal, 18, discountPercentage));
                }
            } else {
                navigate('/buy-now');
            }
        }
    }, [discountPercentage]);


    const handleRemoveCouponCode = (e) => {
        e.preventDefault();
        setCouponCode('');
        setIsValidCouponCode(false);
        setDiscountPercentage(0);
    };

    //Razorpay Script
    const loadRazorpayScript = () => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    // PLace Order
    const [loadingg, setLoadingg] = useState(false)

    const handleProceed = async () => {
        setErrShow(true);
        setLoadingg(true);

        if (Number(price.grandTotal) > 100000) {
            toast.error('Orders above ₹1,00,000 INR can not be placed!', toastStyle)
            setTimeout(() => {
                navigate('/buy-now');
                setLoadingg(false);
            }, 2000);
            return
        } else {
            try {
                let finalData = {};

                if (isValidCouponCode) {
                    finalData = {
                        ...ticketData,
                        couponCode: couponCode
                    };
                } else {
                    finalData = { ...ticketData };
                }

                // First, create the order via the backend
                const response = await axios.post(createTicketOrder, finalData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = response.data;
                const result = response.data.result1;

                if (response.status !== 201) {
                    console.error('Error creating order:', data);
                    setLoadingg(false);
                    return;
                }

                // Store necessary data in localStorage
                localStorage.setItem('orderId', result._id);
                localStorage.setItem('isEmailSend', false);

                if (data.userToken) {
                    localStorage.setItem('token', data.userToken);
                    setIsLogin(true);
                }

                // Load Razorpay script and handle any errors
                const isScriptLoaded = await loadRazorpayScript();
                if (!isScriptLoaded) {
                    toast.error('Razorpay SDK failed to load. Please try again later!!', toastStyle);
                    setLoadingg(false);
                    return;
                }

                // Razorpay options configuration
                const options = {
                    amount: result.OrderTotal * 100, // Amount in paise
                    currency: 'INR',
                    name: result.UserInfo.name,
                    order_id: result.Razor_OrderId, // Razorpay order ID
                    handler: async function (response) {
                        if (response) {
                            localStorage.removeItem('purchaseTicketsData');
                            navigate('/orderstatus');
                        }
                    },
                    prefill: {
                        name: result.UserInfo.name,
                        email: result.UserInfo.email,
                        contact: Number(result.UserInfo.phoneNumber),
                    },
                    theme: {
                        color: '#b32f24',
                    },
                };

                // Initialize Razorpay and open the payment modal
                const rzp = new window.Razorpay(options);
                rzp.open();

            } catch (err) {
                // Handle specific error codes
                if (err.response && err.response.status === 410) {
                    toast.error(err.response.data.message, toastStyle);
                    localStorage.removeItem('purchaseTicketsData');
                    setTimeout(() => {
                        navigate('/buy-now');
                        setLoadingg(false);
                    }, 1000);
                } else {
                    console.error('Error in Razorpay process:', err);
                }
            } finally {
                // Ensure loading is disabled after all operations
                setTimeout(() => {
                    setLoadingg(false);
                }, 1000);
            }
        }
    };

    // Summary Block show Hide In Mobile
    const [show, setShow] = useState(true)
    const handleShowSummary = () => {
        if (show) {
            setShow(false)
        } else {
            setShow(true)
        }
    }

    return (
        <div className='checkoutSection'>
            <div className='container'>
                <div className='checkout'>
                    <div className='info'>
                        <div className='contactInfo'>
                            <div className='checkoutHeader'>
                                <div id="nav">
                                    <NavLink to="/">
                                        <img src={NagriLogo} alt="Nagri Logo" />
                                    </NavLink>
                                    <div className='breadcrumb'>
                                        <ul>
                                            <li><NavLink to='/buy-now'>Select Tickets</NavLink></li>
                                            <li><p className='current'>Information</p></li>
                                            <li><p>Payment</p></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {
                                isLogin ?
                                    <>
                                        <div className='Contact'>
                                            <div className="profileDetails">
                                                <div className="tital">
                                                    <h5>Contact Information</h5>
                                                    <p>Passes will be sent to the contact information below</p>
                                                </div>

                                                <div className="detail">
                                                    <div className="item w-100">
                                                        <h6>Name </h6>
                                                        <p>{userDetails.name}</p>
                                                    </div>
                                                    <div className="item">
                                                        <h6>Email </h6>
                                                        <p>{userDetails.email}</p>
                                                    </div>
                                                    <div className="item">
                                                        <h6>Phone </h6>
                                                        <p>{userDetails.phone}</p>
                                                    </div>
                                                    {
                                                        userDetails.Address ?
                                                            <div className="item w-100">
                                                                <h6>Address </h6>
                                                                <p>{userDetails.Address}, {userDetails.City}, {userDetails.Country}, {userDetails.ZipCode}</p>
                                                            </div> : ""
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={loadingg ? 'payment text-right loading' : 'payment text-right'}>
                                            <Button className='common-btn justify-content-center m-0 w-100' onClick={(e) => handleProceed(e)} disabled={loadingg}>Proceed to Payment {loadingg ? <span className='loader2'></span> : ""}</Button>
                                        </div>
                                    </> :
                                    <>
                                        <div className='Contact'>
                                            <h3>Contact Information</h3>
                                            <p>Passes will be sent to the contact information below</p>
                                            <Form className="user-profile-form">
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} controlId="name">
                                                        <Form.Label>Your Name</Form.Label>
                                                        <div>
                                                            <Form.Control type='text' className="user-form-fieldd21" placeholder="Your Name" name='name' value={ticketData?.name} onChange={(e) => handleChange(e)} />
                                                            {errShow ? <p className='error-msg'>{errorData?.name?.message}</p> : ""}
                                                        </div>
                                                    </Form.Group>
                                                </Row>

                                                <Row className="mb-3 bottom-row">
                                                    <Form.Group as={Col} controlId="phone">
                                                        <Form.Label>Phone No.</Form.Label>
                                                        <div>
                                                            <Form.Control type='number' className="user-form-fieldd21" placeholder='Enter Your Phone no.' name='phone' value={ticketData?.phone} onChange={(e) => handleChange(e)} />
                                                            {errShow ? <p className='error-msg'>{errorData?.phone?.message}</p> : ""}
                                                        </div>
                                                    </Form.Group>

                                                    <Form.Group as={Col} controlId="email">
                                                        <Form.Label>Email</Form.Label>
                                                        <div>
                                                            <div className='otpInput'>
                                                                <Form.Control type='email' className="user-form-fieldd21" placeholder="Enter Email" name='email' value={ticketData?.email} onChange={(e) => handleChange(e)} />
                                                                <NavLink className={isOtpSend ? "common-btn disabled m-0" : "common-btn m-0"} onClick={(e) => handleSendOtp(e)} disabled={isOtpSend}>Get Otp</NavLink>
                                                            </div>
                                                            {errShow ? <p className='error-msg'>{errorData?.email?.message}</p> : ""}
                                                        </div>
                                                    </Form.Group>
                                                </Row>

                                                {
                                                    isOtpSend &&
                                                    <Row className="mb-3">
                                                        <Form.Group as={Col} className="form-control otp-inputs" controlId="formBasicPassword">
                                                            <Form.Label>Enter Otp</Form.Label>
                                                            <div className='varifyOTP'>
                                                                <OTPInput
                                                                    className="otp-field"
                                                                    value={OTP}
                                                                    onChange={(e) => handleOTPChange(e)}
                                                                    numInputs={4}
                                                                    renderInput={(props, index) => (
                                                                        <input
                                                                            {...props}
                                                                            type="number"
                                                                            pattern="[0-9]*"
                                                                            inputMode="numeric"
                                                                            disabled={isVerified}
                                                                        />
                                                                    )}
                                                                />
                                                                {
                                                                    showVarified && OTP.length === 4 && <>
                                                                        {isVerified ?
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 100 100">
                                                                                <path id="checkmark" d="M25 50 L40 65 L75 30" fill="transparent" stroke="#730000" stroke-width="6" stroke-dasharray="100" stroke-dashoffset="100">
                                                                                    <animate attributeName="stroke-dashoffset" begin="0s" dur="1s" to="0" fill="freeze" />
                                                                                </path>
                                                                            </svg> :
                                                                            !isVerified ?
                                                                                <svg width="30" height="30" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M7 7.9375C7.30469 7.9375 7.5625 8.19531 7.5625 8.5C7.5625 8.82812 7.30469 9.0625 7 9.0625C6.67188 9.0625 6.4375 8.82812 6.4375 8.5C6.4375 8.19531 6.67188 7.9375 7 7.9375ZM7 7C6.78906 7 6.625 6.83594 6.625 6.625V3.25C6.625 3.0625 6.78906 2.875 7 2.875C7.1875 2.875 7.375 3.0625 7.375 3.25V6.625C7.375 6.83594 7.1875 7 7 7ZM12.8125 8.82812C13.0469 9.22656 13.0469 9.69531 12.8125 10.0938C12.5781 10.5156 12.1562 10.75 11.6641 10.75H2.3125C1.82031 10.75 1.39844 10.5156 1.16406 10.0938C0.929688 9.69531 0.929688 9.22656 1.16406 8.82812L5.85156 0.90625C6.08594 0.507812 6.50781 0.25 7 0.25C7.46875 0.273438 7.89062 0.507812 8.125 0.90625L12.8125 8.82812ZM12.1562 9.71875C12.2734 9.55469 12.25 9.36719 12.1562 9.20312L7.46875 1.28125C7.375 1.11719 7.1875 1.02344 7 1C6.78906 1 6.60156 1.11719 6.50781 1.28125L1.82031 9.20312C1.72656 9.36719 1.70312 9.55469 1.82031 9.71875C1.91406 9.90625 2.10156 10 2.3125 10H11.6641C11.875 10 12.0625 9.90625 12.1562 9.71875Z" fill="#730000" />
                                                                                </svg>
                                                                                : ""}
                                                                    </>
                                                                }
                                                            </div>

                                                            <p className='resend-btn'>
                                                                {
                                                                    !isVerified &&
                                                                    <>
                                                                        {timer > 0
                                                                            ? `Resend code in ${timer} seconds`
                                                                            : <><span onClick={(e) => handleSendOtp(e, "otpLess")}>Resend OTP</span></>}
                                                                    </>
                                                                }
                                                            </p>
                                                        </Form.Group>
                                                    </Row>
                                                }

                                                {showOtpLess &&
                                                    <Row className='mb-3'>
                                                        <Form.Group as={Col} className="checkbox" controlId="formBasicCheckbox">
                                                            <div className='check'>
                                                                <Form.Check type="checkbox" onChange={(e) => handleChecked(e, "otpLess")} disabled={isVerified} name="tryOtpLess" label="Checkout without Email Id verification" />
                                                            </div>
                                                        </Form.Group>
                                                    </Row>
                                                }
                                            </Form>
                                        </div>
                                        <div className={loadingg ? 'payment text-right loading' : 'payment text-right'}>
                                            <Button className='common-btn justify-content-center m-0 w-100' onClick={(e) => handleProceed(e)} disabled={!isVerified || !otpLess || loadingg}>Proceed to Payment {loadingg ? <span className='loader2'></span> : ""}</Button>
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                    <div className='checkoutSummary'>
                        <div className='summaryToggle'>
                            <Button className={show ? 'w-100 show' : "w-100"} onClick={(e) => handleShowSummary(e)}>
                                <p className='text'><BsTicketPerforated /> {show ? "Hide ticket summary" : "Show ticket summary"}<span className={show ? "" : "up"}>&#8250;</span></p>
                                <p className='price'>&#8377;{price.grandTotal}</p>
                            </Button>
                        </div>
                        <div className={show ? 'ticketsBlock show' : "ticketsBlock"}>
                            {purchaseData?.purchaseTicketsData?.map((ticket) => (
                                <div
                                    className={
                                        ticket.isCombo
                                            ? 'ticket combo selected'
                                            : 'ticket selected'
                                    }
                                    key={ticket._id}>
                                    <div className='border'></div>
                                    <div className='ticketData'>
                                        <div className='name'>
                                            <h2>{ticket.TicketTitle} <span>{ticket.Description}</span></h2>
                                        </div>
                                        <div className='booking'>
                                            <div className="price">
                                                <h3>
                                                    {ticket.SalePrice && ticket.SalePrice > 0 ? (
                                                        <>
                                                            <span className='strikePrice' style={{ textDecoration: 'line-through', marginRight: '10px' }}>
                                                                &#8377;{ticket.Price}
                                                            </span>
                                                            &#8377;{ticket.SalePrice}
                                                        </>
                                                    ) : (
                                                        <>&#8377;{ticket.Price}</>
                                                    )}
                                                    <span>({ticket.PhaseName})</span>
                                                </h3>
                                                <p>(Price incl. of 18% GST)</p>
                                            </div>
                                            <div className='quantity'>
                                                <div className='action'>
                                                    <span className='qty'>{ticket.quantity}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className='discount-code'>
                            <Form onSubmit={(e) => handleApplyCouponCode(e)}>
                                <Form.Group as={Col} controlId="email">
                                    <div>
                                        <div className='discountInput'>
                                            <div className='field'>
                                                <Form.Control type='text' className={isValidCouponCode ? "user-form-field323 disabled" : "user-form-field323"} placeholder="Discount Code" name='couponCode' disabled={isValidCouponCode} value={couponCode} onChange={(e) => handleCouponCodeChange(e)} />
                                                <NavLink className={couponCode.length > 0 ? "visible clear-btn" : "hidden clear-btn"} onClick={(e) => handleRemoveCouponCode(e)} disabled={isOtpSend}><AiOutlineCloseCircle /></NavLink>
                                            </div>
                                            <NavLink className={isValidCouponCode ? "common-btn disabled m-0" : "common-btn m-0"} onClick={(e) => handleApplyCouponCode(e)} disabled={isValidCouponCode}>Apply</NavLink>
                                        </div>
                                        {couponCodeErrorShow ? <p className='error-msg'>{couponCodeError}</p> : ""}
                                        {isValidCouponCode ? <p className='success-msg'><i>"{couponCode}"</i> Applied!! <br /> You Saved &#8377;{price.discountAmount} on this order!</p> : ""}
                                    </div>
                                </Form.Group>
                            </Form>
                        </div>

                        <div className={show ? 'total show' : "total"}>
                            {isValidCouponCode && <div className='subtotal'>
                                <p>Discount ({discountPercentage}%)</p>
                                <p>&#8377;{price.discountAmount}</p>
                            </div>}
                            <div className='subtotal'>
                                <p>Net Price</p>
                                <p>&#8377;{price.basePrice}</p>
                            </div>
                            <div className='subtotal'>
                                <p>GST ({price.taxPercentage}%)</p>
                                <p>&#8377;{price.taxPrice}</p>
                            </div>
                            <div className='grandtotal'>
                                <p>Grand Total</p>
                                <p>&#8377;{price.grandTotal}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Miss Match Popup */}
            <Modal scroll width="500px" preventClose className='MissMatchPopup' closeButton aria-labelledby="modal-title" aria-describedby="modal-description" {...productDeleteModalBindings} >
                <Modal.Header justify='start'>
                    <Text id="modal-title" size={18}>
                        You already have an account. <Text b id="modal-title" size={18}> Please login!</Text>
                    </Text>
                </Modal.Header>
                <Modal.Body className='deleteModel'>
                    <Login closeProductDeleteModel={closeProductDeleteModel} ticketData={ticketData} />
                </Modal.Body>
            </Modal>


            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default Checkout
