import React, { useEffect } from 'react'
import './About.css'
import aboutImg from '../../Images/nagri/about-us.svg';
import AboutEle01 from '../../Images/nagri/about-element-01.png';
import AboutEle02 from '../../Images/nagri/about-element-02.png';
import AboutEle03 from '../../Images/nagri/about-element-03.png';
import nagrihouse from '../../Images/home/footer-top.svg';
import nagriDance from '../../Images/nagri/about-last.svg';
import nagriCycle from '../../Images/nagri/about-cycle.png';

function About() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section className='About-Us buyNow'>

            <div className='inner-banner'>
                <div className="container">
                    <h1>About Nagri</h1>
                </div>
            </div>

            <div className="main About">
                <div className="container">
                    <div className='about-container desktop-only'>
                        <h2>The Design:</h2>
                        <p>of Nagri will be thoughtfully curated to capture the true spirit of Community Driven  Small Town Navratri, creating an unforgettable experience for all attendees. Crafted by CEPT professors  with over 5 years of expertise in event infrastructure development, our venue will feature thoughtfully  designed stalls and functional spaces that bring the Nagri theme to life, meticulously design ambience that provides an ideal stage for showcasing your brand.</p>

                        <div className='about-element-01'>
                            <img src={AboutEle01} alt="About Element" />
                        </div>

                        <div className='capacity'>
                            <h2>Capacity:</h2>
                            <p>Designed to host up to 10,000+ attendees comfortably. Features: Spacious layout perfect for dynamic, large-scale celebration</p>
                        </div>

                        <div className='row'>
                            <div className='about-element-02'>
                                <img src={AboutEle02} alt="About Element" />
                            </div>

                            <div className='performance'>
                                <h2>Performance by the Nayak Legacy:</h2>
                                <p>We bring you an extraordinary cultural experience featuring the renowned Nayak family from Viramgam. With over 20 years of mastery, this generational talent specializes in the timeless art of drumming and playing the shehnai. The Nayak family, whose heritage is deeply rooted in the Gujarati tradition of singing, dancing, and performing plays, promises to deliver an unforgettable performance that honors the authenticity of Navratri</p>
                                <p>"Non Stop Garba, No Time Limit</p>
                            </div>
                        </div>

                        <div className='last-ele'>
                            <div className='about-element-03'>
                                <img src={AboutEle03} alt="About Element" />
                            </div>

                            <p>We will incorporate traditional Nagri aesthetics into every aspect of the event. From the décor to the music. A community-centric atmosphere   where every part of the design is a functional element that captures the heart of Navratri.</p>

                            <div className='about-us'>
                                <h2>The NAGRI Experience for Navratri:</h2>
                                <p>At Nagri Na Norta, we are committed to delivering the most authentic and immersive Nagri experience for Navratri. Our approach seamlessly integrates the timeless charm of traditional Nagri - the sense of community and celebration that feels both genuine and exhilarating.</p>
                            </div>
                        </div>
                    </div>

                    <div className='about-container mobile-only'>
                        <h2>The Design:</h2>
                        <p>of Nagri will be thoughtfully curated to capture the true spirit of Community Driven  Small Town Navratri, creating an unforgettable experience for all attendees. Crafted by CEPT professors  with over 5 years of expertise in event infrastructure development, our venue will feature thoughtfully  designed stalls and functional spaces that bring the Nagri theme to life, meticulously design ambience that provides an ideal stage for showcasing your brand.</p>

                        <div className='about-element-01'>
                            <img src={nagrihouse} alt="About Element" />
                        </div>

                        <div className='capacity-section'>
                            <div className='capacity'>
                                <h2>Capacity:</h2>
                                <p>Designed to host up to 10,000+ attendees comfortably. Features: Spacious layout perfect for dynamic, large-scale celebration</p>
                            </div>

                            <div className='about-element'>
                                <img src={aboutImg} alt="About Element" />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='performance'>
                                <h2>Performance by the Nayak Legacy:</h2>
                                <p><img src={AboutEle02} alt="About Element" /> We bring you an extraordinary cultural experience featuring the renowned Nayak family from Viramgam. With over 20 years of mastery, this generational talent specializes in the timeless art of drumming and playing the shehnai. The Nayak family, whose heritage is deeply rooted in the Gujarati tradition of singing, dancing, and performing plays, promises to deliver an unforgettable performance that honors the authenticity of Navratri</p>
                                <p>"Non Stop Garba, No Time Limit</p>
                            </div>
                        </div>

                        <div className='about-cycle'>
                            <p><img src={nagriCycle} alt="About Element" />We will incorporate traditional Nagri aesthetics into every aspect of the event. From the décor to the music. A community-centric atmosphere   where every part of the design is a functional element that captures the heart of Navratri.</p>
                        </div>

                        <div className='about-last'>
                            <div className='about-us'>
                                <h2>The NAGRI Experience for Navratri:</h2>
                                <p> <img src={nagriDance} alt="About Element" />At Nagri Na Norta, we are committed to delivering the most authentic and immersive Nagri experience for Navratri. Our approach seamlessly integrates the timeless charm of traditional Nagri - the sense of community and celebration that feels both genuine and exhilarating.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default About